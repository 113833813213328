import { Component, ViewChild } from '@angular/core';
import { AppNavigator, NavigatorTarget } from "../../services/app-navigator";
import { User } from "../../business/interfaces/User";
import { InputField } from "../interfaces/InputField";
import { FormGroup, Validators } from "@angular/forms";
import { ErrorHandling } from "../../common/classes/ErrorHandling";
import { ActivatedRoute } from "@angular/router";
import { UserAPI } from "../../business/apis/UserAPI";
import { BehaviorSubject, map } from "rxjs";
import { UserNameId } from "../../business/name-id/UserNameId";
import { getEnumFromValue } from "../../common/utilities/General";
import { isFormSubmitted } from "../../common/utilities/FormsHelper";
import { InputFieldType } from "../../components/input/enum/InputFieldType";
import { InputType } from "../../components/input/enum/InputType";
import { MobileNumberComponent } from "../../components/input/mobile-number/mobile-number.component";
import { OptionSelect } from "../../components/filters/enum/filter-type";
import { GenderHelper } from "../../business/enums/Gender";
import { UserDatabase } from "../../business/database/user-database.service";

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent {
  static navigatorName: string = NavigatorTarget.userDetails.valueOf();

  userId: string = "";
  userDetails: User | null = null;
  userFields: InputField[] = [];
  form: FormGroup = new FormGroup({});
  errorHandling: ErrorHandling | null = null;
  isLoading = false;

  nameItem!: InputField;
  emailItem!: InputField;
  passwordItem!: InputField;
  // birthDateItem!: InputField;
  isAdminItem!: InputField;
  // genderItem!: InputField;

  // @ViewChild(MobileNumberComponent)
  // mobileNumberField?: MobileNumberComponent;


  Validators = Validators
  userNameId = UserNameId

  constructor(
    private activatedRoute: ActivatedRoute,
    private userApi: UserAPI,
    private appNavigator: AppNavigator,
    private userDatabase: UserDatabase,
  ) {
  }

  ngOnInit(): void {
    this.userFields = this.getInputFields();
    this.activatedRoute.params
      .pipe(map((parameters) => parameters["id"]))
      .subscribe((id) => {
        this.userId = id;
        this.reloadDataSource()
      });
  }


  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {
  }

  getInputFieldById(id: UserNameId): InputField | undefined {
    let item = this.userFields.find((field) => field.id === id);
    return item;
  }

  updateInputFieldById(id: string, value: any) {
    let fieldId = getEnumFromValue(UserNameId, id) as UserNameId
    let field = this.getInputFieldById(fieldId);
    if (field != undefined) {
      field.value = value;
    }
  }

  getValueFieldById(id: string): Object {
    let fieldId = getEnumFromValue(UserNameId, id) as UserNameId
    let field = this.getInputFieldById(fieldId);
    return field?.value ?? null;
  }

  submit() {
    if (isFormSubmitted(this.form)) {
      this.updateUser();
    }
  }

  navigateBack(): void {
    this.appNavigator.back();
  }

  getListProductState(): OptionSelect[] {
    return GenderHelper.getListOption();
  }


  private updateUser() {

    // let countryCodeValue =
    //   this.mobileNumberField?.selectedCountry?.countryCode ?? "";
    // let phoneNumberValue = this.mobileNumberField?.mobileNumber ?? "";
    // let gender = (this.getValueFieldById(UserNameId.GENDER) as OptionSelect).value;

    let merchantId = this.userDatabase.getCurrentUser()?.merchantId

    let user = {
      id: this.userDetails?.id,
      name: this.getValueFieldById(UserNameId.NAME) as string ?? "",
      email: this.getValueFieldById(UserNameId.EMAIL) as string ?? "",
      isAdmin: this.getValueFieldById(
        UserNameId.IS_ADMIN
      ) as boolean ?? false,
      merchantId: merchantId,
    } as User;


    this.restError();
    this.isLoading = true;
    this.userApi.save({
      oldUser: this.userDetails,
      user: user,
    }).subscribe({
      next: (item) => {
        console.log(item);
        this.navigateBack()
      },
      error: (error: any) => {
        this.checkError(error);
        this.isLoading = false;
      },
    });
  }

  private reloadDataSource() {
    if (this.userId?.hasActualValue()) {
      this.userApi
        .getDetails({ userId: this.userId })
        .subscribe({
          next: (item: User) => {
            // Handle successful data retrieval
            this.userDetails = item;
            this.reloadDataViews();
          },
          error: (error: any) => {
            // Handle error
            console.error("Error:", error);
            this.reloadDataViews();
          },
        });
    } else {
      this.reloadDataViews();
    }
  }

  private reloadDataViews() {
    this.getInputFieldById(UserNameId.NAME)?.loadData?.next(
      this.userDetails?.name
    );
    this.getInputFieldById(UserNameId.EMAIL)?.loadData?.next(
      this.userDetails?.email
    );
    // this.getInputFieldById(UserNameId.BIRTHDATE)?.loadData?.next(
    //   this.userDetails?.birthDate
    // );

    this.getInputFieldById(UserNameId.IS_ADMIN)?.loadData?.next(
      this.userDetails?.isAdmin
    );


    // let countryCode = this.userDetails?.countryCode ?? "";
    // if (countryCode?.hasActualValue()) {
    //   this.mobileNumberField?.updateViews([this.userDetails?.countryCode, this.userDetails?.phoneNumber]);
    // } else {
    //   this.mobileNumberField?.updateCountrySelected("Egy");
    // }

    // let gender = GenderHelper.getOption(this.userDetails?.gender ?? "")
    // this.getInputFieldById(
    //   UserNameId.GENDER
    // )?.loadData?.next(gender);

  }

  private checkError(error: any) {
    let errorHandling = ErrorHandling.configurationError(error);
    this.errorHandling = errorHandling;
  }

  private restError() {
    this.errorHandling = null;
  }

  private getInputFields(): InputField[] {
    this.nameItem = {
      id: UserNameId.NAME,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "Name",
      validators: [Validators.required],
      value: "",
      loadData: new BehaviorSubject<any>(""),
    } as InputField;

    this.emailItem = {
      id: UserNameId.EMAIL,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "Email",
      validators: [Validators.required],
      loadData: new BehaviorSubject<any>(""),
    } as InputField;


    // this.birthDateItem = {
    //   id: UserNameId.BIRTHDATE,
    //   inputFieldType: InputFieldType.textField,
    //   floatingLabel: "Birth Date",
    //   validators: [],
    //   loadData: new BehaviorSubject<any>(""),
    // } as InputField;


    this.passwordItem = {
      id: UserNameId.PASSWORD,
      inputFieldType: InputFieldType.textField,
      floatingLabel: "Password",
      validators: this.userDetails ? [Validators.required] : [],
      loadData: new BehaviorSubject<any>(""),
    } as InputField;


    // this.genderItem = {
    //   id: UserNameId.GENDER,
    //   floatingLabel: "Gender",
    //   validators: [Validators.required],
    //   loadData: new BehaviorSubject<any>(null),
    // } as InputField;

    this.isAdminItem = {
      id: UserNameId.IS_ADMIN,
      inputFieldType: InputFieldType.checkBox,
      label: "Is Admin",
      value: false,
      validators: [],
      loadData: new BehaviorSubject<any>(false),
    } as InputField;


    return [
      this.nameItem,
      this.emailItem,
      // this.birthDateItem,
      // this.passwordItem,
      this.isAdminItem,
      // this.genderItem,
    ];
  }

}
