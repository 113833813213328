import {Component} from '@angular/core';
import {BehaviorSubject, map} from "rxjs";
import {Category} from "../../business/interfaces/Category";
import {InputField} from "../interfaces/InputField";
import {FormGroup, Validators} from "@angular/forms";
import {ErrorHandling} from "../../common/classes/ErrorHandling";
import {ActivatedRoute} from "@angular/router";
import {CategoryAPI} from "../../business/apis/CategoryAPI";
import {InputFieldType} from "../../components/input/enum/InputFieldType";
import {InputType} from "../../components/input/enum/InputType";
import {isFormSubmitted} from "../../common/utilities/FormsHelper";
import {CategoryNameId} from "../../business/name-id/CategoryNameId";
import {AppNavigator, NavigatorTarget} from "../../services/app-navigator";
import {getEnumFromValue} from "../../common/utilities/General";

@Component({
  selector: 'app-category-details',
  templateUrl: './category-details.component.html',
  styleUrls: ['./category-details.component.css']
})
export class CategoryDetailsComponent {

  static navigatorName: string = NavigatorTarget.categoryDetails.valueOf();

  categoryId: string = "";
  categoryDetails: Category | null = null;
  categoryFields: InputField[] = [];
  form: FormGroup = new FormGroup({});
  errorHandling: ErrorHandling | null = null;
  isLoading = false;

  nameEnglishItem!: InputField;
  nameArabicItem!: InputField;
  descriptionEnglishItem!: InputField;
  descriptionArabicItem!: InputField;


  constructor(
    private activatedRoute: ActivatedRoute,
    private categoryApi: CategoryAPI,
    private appNavigator: AppNavigator,
  ) {
  }

  ngOnInit(): void {
    this.categoryFields = this.getInputFields();

    this.activatedRoute.params
      .pipe(map((parameters) => parameters["id"]))
      .subscribe((id) => {
        this.categoryId = id;
        this.reloadDataSource(
          () => {
          },
          () => {
          }
        );
      });

  }


  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {
  }

  getInputFieldById(id: CategoryNameId): InputField | undefined {
    let item = this.categoryFields.find((field) => field.id === id);
    return item;
  }

  updateInputFieldById(id: string, value: any) {
    let fieldId =  getEnumFromValue(CategoryNameId,id) as CategoryNameId
    let field = this.getInputFieldById(fieldId);
    if (field != undefined) {
      field.value = value;
    }
  }

  getValueFieldById(id: string): Object {
    let fieldId =  getEnumFromValue(CategoryNameId,id) as CategoryNameId
    let field = this.getInputFieldById(fieldId);
    return field?.value ?? null;
  }

  submit() {
    if (isFormSubmitted(this.form)) {
      this.updateCategory();
    }
  }

  updateCategory() {
    let category = {
      id: this.categoryDetails?.id ?? "",
      nameEn:
        this.getValueFieldById(CategoryNameId.NAME_EN)?.toStringValue() ?? "",
      nameAr:
        this.getValueFieldById(CategoryNameId.NAME_AR)?.toStringValue() ?? "",
      descriptionEn:
        this.getValueFieldById(CategoryNameId.DESCRIPTION_AR)?.toStringValue() ?? "",
      descriptionAr:
        this.getValueFieldById(CategoryNameId.DESCRIPTION_EN)?.toStringValue() ?? "",
    } as Category;

    console.log(category);
    this.restError();
    this.isLoading = true;
    this.categoryApi.save({category: category}).subscribe({
      next: (item) => {
        console.log(item);
        if (item.hasValue()) {
          this.categoryId = item;
        }
        this.navigateBack()
      },
      error: (error: any) => {
        this.checkError(error);
        this.isLoading = false;
      },
    });
  }

  reloadDataSource(completionHandler: () => void, errorHandler: () => void) {
    if (this.categoryId?.hasActualValue()) {
      this.categoryApi
        .getDetails({categoryId: this.categoryId})
        .subscribe({
          next: (item: Category) => {
            // Handle successful data retrieval
            this.categoryDetails = item;
            this.reloadDataViews();
            errorHandler(); // Call the completion handler
          },
          error: (error: any) => {
            // Handle error
            console.error("Error:", error);
            this.reloadDataViews();
            errorHandler(); // Call the completion handler
          },
        });
    } else {
      this.reloadDataViews();
      completionHandler(); // Call the completion handler
    }
  }

  navigateBack(): void {
    this.appNavigator.back();
  }

  reloadDataViews() {
    this.getInputFieldById(CategoryNameId.NAME_EN)?.loadData?.next(
      this.categoryDetails?.nameEn
    );
    this.getInputFieldById(CategoryNameId.NAME_AR)?.loadData?.next(
      this.categoryDetails?.nameAr
    );
    this.getInputFieldById(CategoryNameId.DESCRIPTION_EN)?.loadData?.next(
      this.categoryDetails?.descriptionEn
    );
    this.getInputFieldById(CategoryNameId.DESCRIPTION_AR)?.loadData?.next(
      this.categoryDetails?.descriptionAr
    );

  }

  checkError(error: any) {
    let errorHandling = ErrorHandling.configurationError(error);
    this.errorHandling = errorHandling;
  }

  restError() {
    this.errorHandling = null;
  }

  private getInputFields(): InputField[] {
    this.nameEnglishItem = {
      id: CategoryNameId.NAME_EN,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "Name English",
      validators: [Validators.required],
      value: "",
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.nameArabicItem = {
      id: CategoryNameId.NAME_AR,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "Name Arabic",
      validators: [Validators.required],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.descriptionEnglishItem = {
      id: CategoryNameId.DESCRIPTION_EN,
      inputFieldType: InputFieldType.textArea,
      floatingLabel: "Description English",
      validators: [],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.descriptionArabicItem = {
      id: CategoryNameId.DESCRIPTION_AR,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "Description Arabic",
      validators: [],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;


    return [
      this.nameEnglishItem,
      this.nameArabicItem,
      this.descriptionEnglishItem,
      this.descriptionArabicItem,

    ];
  }

}

