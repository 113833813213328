

<main *ngIf="!loginWithWebSite"
  class="d-flex w-100">
  <div class="container d-flex flex-column">
    <div class="row vh-100">
      <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
        <div class="d-table-cell align-middle">

          <div class="text-center mt-4">
            <h1 class="h2">Welcome back!</h1>
            <p class="lead">
              Sign in to your account to continue
            </p>
          </div>

          <div class="card">
            <div class="card-body">
              <div class="m-sm-3">

                <form [formGroup]="loginForm" (submit)="login()">
                  <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInputEmail" formControlName="email" placeholder="Email Address" [ngClass]="{'is-invalid': (email?.touched && email?.invalid) || emailErrorMessage !== null}">
                    <label for="floatingInputEmail">Email Address</label>
                    <div class="invalid-feedback" *ngIf="email?.touched && email?.invalid">
                      <div *ngIf="email?.hasError('required')">Email is required.</div>
                      <div *ngIf="email?.hasError('email')">Incorrect email format.</div>
                    </div>
                    <div class="invalid-feedback" *ngIf="emailErrorMessage !== null">{{ emailErrorMessage }}</div>
                  </div>
                  <div class="form-floating mb-3 password-div">
                    <input [type]="showPasswordFieldText ? 'text' : 'password'" class="form-control password-field" id="floatingInputPassword" formControlName="password" placeholder="Password" [ngClass]="{'is-invalid': (password?.touched && password?.invalid) || passwordErrorMessage !== null}">
                    <label for="floatingInputPassword">Password</label>
                    <i class="bi bi-eye-fill password-show-icon" (click)="togglePasswordShowButtonClicked()"></i>
                    <div class="invalid-feedback" *ngIf="password?.touched && password?.invalid">
                      <div *ngIf="password?.hasError('required')">Password is required.</div>
                      <div *ngIf="password?.hasError('minlength')">Password must be more than 8 characters.</div>
                      <div *ngIf="password?.hasError('maxlength')">Password too long.</div>
                      <!--        <div *ngIf="password?.hasError('passwordStrength')">Password must contain at least one uppercase letter, one lowercase letter, and one special character.</div>-->
                    </div>
                    <div class="invalid-feedback" *ngIf="passwordErrorMessage !== null">{{ passwordErrorMessage }}</div>
                  </div>
                  <div class="alert alert-danger " *ngIf="serverErrorMessage !== null">{{ serverErrorMessage }}</div>

                  <div class="d-grid gap-2 mt-3">
                    <button type="submit" class="btn btn-lg btn-primary" *ngIf="!isLoading">Sign in</button>

                    <button class="btn btn-primary" type="button" disabled *ngIf="isLoading">
                      <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                      <span class="visually-hidden" role="status">Loading...</span>
                    </button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
