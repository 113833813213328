

export enum CityMiniObjectNameId {
  ID = 'city.id',
  NAME_EN = 'city.nameEn',
  NAME_AR = 'city_nameAr',
  COUNTRY = 'city.nameAr',
}

export enum MerchantMiniObjectNameId {
  MERCHANT_OBJECT_ID = "merchant.id",
  MERCHANT_SLUG = "merchant.slug",
  MERCHANT_NAME_EN= "merchant.nameEn",
  MERCHANT_NAME_Ar= "merchant.nameAr",
}


export enum CategoryMiniObjectNameId {
  CATEGORY_OBJECT_ID = "category.id",
  CATEGORY_NAME_EN= "category.nameEn",
  CATEGORY_NAME_Ar= "category.nameAr",
}

export enum ProductMiniObjectNameId {
  PRODUCT_OBJECT_ID = "product.id",
  PRODUCT_NAME_EN= "product.nameEn",
  PRODUCT_NAME_Ar= "product.nameAr",
  PRODUCT_SLUG= "product.slug",
}
