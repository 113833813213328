import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'secondsToMinutesSeconds'
})
export class SecondsToMinutesSecondsPipe implements PipeTransform {
  transform(value: number): string {
    if (!value && value !== 0) {
      return '';
    }

    // const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    const seconds = value % 60;

    // return [hours, minutes, seconds]
    return [minutes, seconds]
      .map(v => v < 10 ? '0' + v : v) // Add leading zeros
      .join(':');
  }
}
