import {Component, Input} from '@angular/core';
import {AppNavigator, NavigatorTarget} from "../../services/app-navigator";
import {BehaviorSubject, map} from "rxjs";
import {Product} from "../../business/interfaces/Product";
import {ErrorHandling} from "../../common/classes/ErrorHandling";
import {ActivatedRoute} from "@angular/router";
import {ProductAPI} from "../../business/apis/ProductAPI";
import {InputField} from "../interfaces/InputField";

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent {
  static navigatorName: string = NavigatorTarget.productDetails.valueOf();
  loadBasicInfoData: BehaviorSubject<any>;
  productDetails: Product | null = null;

  productId: string = "";
  merchantId: string = "";

  errorHandling: ErrorHandling | null = null;
  isLoading = false



  constructor(
    private activatedRoute: ActivatedRoute,
    private appNavigator: AppNavigator,
    private productApi: ProductAPI
  ) {
    this.loadBasicInfoData = new BehaviorSubject<any>([]);
  }


  ngOnInit(): void {
    this.activatedRoute.params.subscribe((parameters) => {
      this.merchantId = parameters["merchantId"] ?? "";
      this.productId  = parameters["id"] ?? "";
      this.reloadDataSource();
    });
  }

  ngAfterViewInit(): void {
  }


  getProduct(id: string) {
    this.productId = id;
    this.reloadDataSource();
  }

  reloadDataSource() {
    if (this.productId?.hasActualValue()) {
      this.productApi
        .getDetails({productId: this.productId})
        .subscribe({
          next: (item: Product) => {
            // Handle successful data retrieval
            this.productDetails = item;
            this.reloadDataViews();// Call the completion handler
          },
          error: (error: any) => {
            // Handle error
            console.error("Error:", error);
            this.reloadDataViews();// Call the completion handler
          },
        });
    } else {
      this.reloadDataViews()
    }
  }

  navigateToMerchant(): void {
    this.appNavigator.navigateTo({
      target: NavigatorTarget.merchantBasicInfo,
    });
  }

  checkError(error: any) {
    let errorHandling = ErrorHandling.configurationError(error);
    this.errorHandling = errorHandling;
  }

  restError() {
    this.errorHandling = null;
  }

  private reloadDataViews() {
    this.loadBasicInfoData.next(this.productDetails)
  }
}

