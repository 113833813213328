import {Component} from '@angular/core';
import {AppNavigator, NavigatorTarget} from "../../services/app-navigator";
import {Country} from "../../business/interfaces/Country";
import {InputField} from "../interfaces/InputField";
import {FormGroup, Validators} from "@angular/forms";
import {ErrorHandling} from "../../common/classes/ErrorHandling";
import {ActivatedRoute} from "@angular/router";
import {CountryAPI} from "../../business/apis/CountryAPI";
import {BehaviorSubject, map} from "rxjs";
import {CountryNameId} from "../../business/name-id/CountryNameId";
import {getEnumFromValue} from "../../common/utilities/General";
import {isFormSubmitted} from "../../common/utilities/FormsHelper";
import {InputFieldType} from "../../components/input/enum/InputFieldType";
import {InputType} from "../../components/input/enum/InputType";

@Component({
  selector: 'app-country-details',
  templateUrl: './country-details.component.html',
  styleUrls: ['./country-details.component.scss']
})
export class CountryDetailsComponent {
  static navigatorName: string = NavigatorTarget.countryDetails.valueOf();

  countryId: string = "";
  countryDetails: Country | null = null;
  countryFields: InputField[] = [];
  form: FormGroup = new FormGroup({});
  errorHandling: ErrorHandling | null = null;
  isLoading = false;

  nameEnglishItem!: InputField;
  nameArabicItem!: InputField;
  iso2letterItem!: InputField;
  iso3letterItem!: InputField;
  countryCodeItem!: InputField;


  Validators = Validators

  constructor(
    private activatedRoute: ActivatedRoute,
    private countryApi: CountryAPI,
    private appNavigator: AppNavigator,
  ) {
  }

  ngOnInit(): void {
    this.countryFields = this.getInputFields();
    this.activatedRoute.params
      .pipe(map((parameters) => parameters["id"]))
      .subscribe((id) => {
        this.countryId = id;
        this.reloadDataSource(
          () => {
          },
          () => {
          }
        );
      });

  }


  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {
  }

  getInputFieldById(id: CountryNameId): InputField | undefined {
    let item = this.countryFields.find((field) => field.id === id);
    return item;
  }

  updateInputFieldById(id: string, value: any) {
    let fieldId = getEnumFromValue(CountryNameId, id) as CountryNameId
    let field = this.getInputFieldById(fieldId);
    if (field != undefined) {
      field.value = value;
    }
  }

  getValueFieldById(id: string): Object {
    let fieldId = getEnumFromValue(CountryNameId, id) as CountryNameId
    let field = this.getInputFieldById(fieldId);
    return field?.value ?? null;
  }

  submit() {
    if (isFormSubmitted(this.form)) {
      this.updateCountry();
    }
  }


  updateCountry() {
    let country = {
      nameEn:
        this.getValueFieldById(CountryNameId.NAME_EN)?.toStringValue() ?? "",
      nameAr:
        this.getValueFieldById(CountryNameId.NAME_AR)?.toStringValue() ?? "",
      iso2letter:
        this.getValueFieldById(CountryNameId.ISO2_LETTER)?.toStringValue() ?? "",
      iso3letter:
        this.getValueFieldById(CountryNameId.ISO3_LETTER)?.toStringValue() ?? "",
      countryCode:
        this.getValueFieldById(CountryNameId.COUNTRY_CODE)?.toStringValue() ?? "",
    } as Country;


    this.restError();
    this.isLoading = true;
    this.countryApi.save({
      id: this.countryId,
      country: country, oldCountry: this.countryDetails
    }).subscribe({
      next: (item) => {
        console.log(item);
        let countryId = this.iso3letterItem.value ?? "";
        this.countryId = countryId;
        this.navigateBack()
      },
      error: (error: any) => {
        this.checkError(error);
        this.isLoading = false;
      },
    });
  }

  reloadDataSource(completionHandler: () => void, errorHandler: () => void) {
    if (this.countryId?.hasActualValue()) {
      this.countryApi
        .getDetails({countryId: this.countryId})
        .subscribe({
          next: (item: Country) => {
            // Handle successful data retrieval
            this.countryDetails = item;
            this.reloadDataViews();
            errorHandler(); // Call the completion handler
          },
          error: (error: any) => {
            // Handle error
            console.error("Error:", error);
            this.reloadDataViews();
            errorHandler(); // Call the completion handler
          },
        });
    } else {
      this.reloadDataViews();
      completionHandler(); // Call the completion handler
    }
  }

  navigateBack(): void {
    this.appNavigator.back();
  }

  reloadDataViews() {
    this.getInputFieldById(CountryNameId.NAME_EN)?.loadData?.next(
      this.countryDetails?.nameEn
    );
    this.getInputFieldById(CountryNameId.NAME_AR)?.loadData?.next(
      this.countryDetails?.nameAr
    );
    this.getInputFieldById(CountryNameId.ISO2_LETTER)?.loadData?.next(
      this.countryDetails?.iso2letter
    );
    this.getInputFieldById(CountryNameId.ISO3_LETTER)?.loadData?.next(
      this.countryDetails?.iso3letter
    );
    this.getInputFieldById(CountryNameId.COUNTRY_CODE)?.loadData?.next(
      this.countryDetails?.countryCode
    );
  }

  checkError(error: any) {
    let errorHandling = ErrorHandling.configurationError(error);
    this.errorHandling = errorHandling;
  }

  restError() {
    this.errorHandling = null;
  }

  private getInputFields(): InputField[] {
    this.nameEnglishItem = {
      id: CountryNameId.NAME_EN,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "Name English",
      validators: [Validators.required],
      value: "",
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.nameArabicItem = {
      id: CountryNameId.NAME_AR,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "Name Arabic",
      validators: [Validators.required],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.iso2letterItem = {
      id: CountryNameId.ISO2_LETTER,
      inputFieldType: InputFieldType.textField,
      floatingLabel: "iso2letter",
      validators: [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(2)],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.iso3letterItem = {
      id: CountryNameId.ISO3_LETTER,
      inputFieldType: InputFieldType.textField,
      floatingLabel: "iso3letter",
      validators: [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(3)],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.countryCodeItem = {
      id: CountryNameId.COUNTRY_CODE,
      inputFieldType: InputFieldType.textField,
      floatingLabel: "Country Code",
      validators: [],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    return [
      this.nameEnglishItem,
      this.nameArabicItem,
      this.iso2letterItem,
      this.iso3letterItem,
      this.countryCodeItem,
    ];
  }
}
