<div>
  <a class="sidebar-brand" href="index.html">
    <span class="align-middle">Akl Bayti</span>
  </a>

  <ul class="sidebar-nav">

<!--    <li [class.active]="NavigationItem.DASHBOARD === selectedItem" class="sidebar-item">-->
<!--      <a (click)="navigateTo(NavigationItem.DASHBOARD)" class="sidebar-link">-->
<!--        <i class="align-middle bi bi-speedometer2"></i> <span class="align-middle">Dashboard</span>-->
<!--      </a>-->
<!--    </li>-->

    <li class="sidebar-header">
      Pages
    </li>

    <li [class.active]="NavigationItem.MERCHANT_BASIC_INFO === selectedItem" class="sidebar-item">
      <a (click)="navigateTo(NavigationItem.MERCHANT_BASIC_INFO)" class="sidebar-link">
        <i class="align-middle bi bi-gear"></i> <span class="align-middle">Basic Info</span>
      </a>
    </li>


    <li [class.active]="NavigationItem.MERCHANT_PRODUCT === selectedItem" class="sidebar-item">
      <a (click)="navigateTo(NavigationItem.MERCHANT_PRODUCT)" class="sidebar-link">
        <i class="align-middle bi bi-grid"></i> <span class="align-middle">Menu</span>
      </a>
    </li>

    <li [class.active]="NavigationItem.MERCHANT_MEDIA === selectedItem" class="sidebar-item">
      <a (click)="navigateTo(NavigationItem.MERCHANT_MEDIA)" class="sidebar-link">
        <i class="align-middle bi bi-images"></i> <span class="align-middle">Images &amp; Videos</span>
      </a>
    </li>

    <li  [class.active]="NavigationItem.LIST_MERCHANT_ADDRESS === selectedItem" class="sidebar-item">
      <a (click)="navigateTo(NavigationItem.LIST_MERCHANT_ADDRESS)" class="sidebar-link">
        <i class="align-middle bi bi-geo-alt"></i> <span class="align-middle">Addresses</span>
      </a>
    </li>

    <li [class.active]="NavigationItem.LIST_MERCHANT_WEBSITE === selectedItem" class="sidebar-item">
      <a (click)="navigateTo(NavigationItem.LIST_MERCHANT_WEBSITE)" class="sidebar-link">
        <i class="align-middle bi bi-globe"></i> <span class="align-middle">Websites</span>
      </a>
    </li>

    <li [class.active]="NavigationItem.LIST_MERCHANT_SOCIAL_MEDIA === selectedItem" class="sidebar-item">
      <a (click)="navigateTo(NavigationItem.LIST_MERCHANT_SOCIAL_MEDIA)" class="sidebar-link">
        <i class="align-middle bi bi-share"></i> <span class="align-middle">Social Media</span>
      </a>
    </li>

    <li [class.active]="NavigationItem.LIST_MERCHANT_PHONES === selectedItem" class="sidebar-item">
      <a (click)="navigateTo(NavigationItem.LIST_MERCHANT_PHONES)" class="sidebar-link">
        <i class="align-middle bi bi-telephone-fill"></i> <span class="align-middle">Phones</span>
      </a>
    </li>

    <li [class.active]="NavigationItem.LIST_MERCHANT_GEOLOCATIONS === selectedItem" class="sidebar-item">
      <a (click)="navigateTo(NavigationItem.LIST_MERCHANT_GEOLOCATIONS)" class="sidebar-link">
        <i class="align-middle bi bi-geo"></i> <span class="align-middle">Geolocations</span>
      </a>
    </li>

    <li [class.active]="NavigationItem.LIST_MERCHANT_AREAS === selectedItem" class="sidebar-item">
      <a (click)="navigateTo(NavigationItem.LIST_MERCHANT_AREAS)" class="sidebar-link">
        <i class="align-middle bi bi-map-fill"></i> <span class="align-middle">Areas</span>
      </a>
    </li>


    <li *ngIf="currentUser?.isAdmin" [class.active]="NavigationItem.USERS === selectedItem" class="sidebar-item">
      <a (click)="navigateTo(NavigationItem.USERS)" class="sidebar-link">
        <i class="align-middle bi bi-people"></i> <span class="align-middle">Users</span>
      </a>
    </li>


    <li class="sidebar-item" [class.active]="NavigationItem.merchantRates === selectedItem">
      <a class="sidebar-link" (click)="navigateTo(NavigationItem.merchantRates)">
        <i class="align-middle" data-feather="sliders"></i> <span class="align-middle">Merchant Rates</span>
      </a>
    </li>


    <li class="sidebar-item" [class.active]="NavigationItem.productsRates === selectedItem">
      <a class="sidebar-link" (click)="navigateTo(NavigationItem.productsRates)">
        <i class="align-middle" data-feather="sliders"></i> <span class="align-middle">Products Rates</span>
      </a>
    </li>

  </ul>
</div>

