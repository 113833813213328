import { Injectable } from '@angular/core';
import { ValidationErrorType } from "../enums/ValidationErrorType";
import { ValidationError } from "../../business/interfaces/ValidationError";
import { Field } from "../enums/Field";
import { Entity } from "../../business/enums/Entity";
import { HttpErrorResponse } from "@angular/common/http";
import {ResponseStatusCode} from "../enums/ResponseStatusCode";

@Injectable({
  providedIn: 'root'
})
export class APIErrorHandler {
  constructor() {}

  public handleApiError(error: HttpErrorResponse): ValidationError[] | string {
    var responseStatusCode = error.status;
    if (responseStatusCode ===  ResponseStatusCode.VALIDATION_ERROR) {
      return this.processValidationErrors(error.error);
    } else if (responseStatusCode === ResponseStatusCode.UNAUTHORIZED) {
      return ValidationErrorType.UNAUTHORIZED
    }
    else if (responseStatusCode === ResponseStatusCode.INTERNAL_SERVER) {
      return 'Server error occurred. Please try again later.';
    } else {
      responseStatusCode = ResponseStatusCode.UNKNOWN
      return 'An unexpected error occurred. Please try again.';
    }
  }

  private processValidationErrors(errors: string[]): ValidationError[] {
    // // Assuming the first error in the array is the primary one
    // const primaryError = errors.length > 0 ? errors[0] : '';
    // return this.translateErrorToEnum(primaryError);
// console.log("incoming errors array = " + errors)
//     return errors.map(uriError => this.translateErrorToEnum(uriError));

    if (Array.isArray(errors)) {
      return errors.map(uriError => this.translateErrorToEnum(uriError));
    } else {
      return[this.translateErrorToEnum(errors)];

    }
  }

  private translateErrorToEnum(uriError: string): ValidationError {
     console.log("INCOMMMMMING " + uriError)
    let validationErrorType: ValidationErrorType = ValidationErrorType.UNKNOWN;
    let field: Field = Field.NONE;

    let parts = uriError.split('/').filter(part => part !== '');
    let firstPart = parts.length > 0 ? parts[0] : null;
    let secondPart = parts.length > 1 ? parts[1] : null;

    if(firstPart === "validation")
    {
      if(secondPart != null)
      {
        let thirdPart = parts.length > 1 ? parts[2] : null;
        return {
          errorType: this.getValidationErrorType(secondPart),
          field: thirdPart != null ? this.getField(thirdPart): undefined,
          fieldName: thirdPart,
          uriError: uriError,
        }
      }
      else {
        return {
          errorType: ValidationErrorType.UNKNOWN
        }
      }
    }
    else if(firstPart === ValidationErrorType.NOT_FOUND)
    {

      return {
        errorType: ValidationErrorType.NOT_FOUND,
        entity: secondPart != null ? this.getEntity(secondPart): undefined,
        uriError: uriError,
      }
    }

    else if(firstPart === ValidationErrorType.TOKEN_MISMATCH)
    {
      return {
        errorType: ValidationErrorType.TOKEN_MISMATCH,
        entity: secondPart != null ? this.getEntity(secondPart): undefined,
        uriError: uriError,
      }
    }




    return {
      errorType: ValidationErrorType.UNKNOWN,
    }

  }



  private getValidationErrorType(errorType: string): ValidationErrorType {
    switch (errorType) {
      case ValidationErrorType.DUPLICATE:
        return ValidationErrorType.DUPLICATE;
      case ValidationErrorType.INVALID_FORMAT:
        return ValidationErrorType.INVALID_FORMAT;
      case ValidationErrorType.REQUIRED:
        return ValidationErrorType.REQUIRED;
      case ValidationErrorType.NOT_FOUND:
        return ValidationErrorType.NOT_FOUND;
    }
    return ValidationErrorType.UNKNOWN
  }

  private getField(fieldName: string): Field {
    switch (fieldName) {
      case Field.AREA_ID:
        return Field.AREA_ID
      case Field.NAME:
        return Field.NAME
      case Field.COUNTRY_CODE:
        return Field.COUNTRY_CODE
      case Field.EMAIL:
        return Field.EMAIL
      case Field.PASSWORD:
        return Field.PASSWORD
      case Field.PHONE_NUMBER:
        return Field.PHONE_NUMBER
      case Field.PRICE:
        return Field.PRICE
      case Field.GENDER:
        return Field.GENDER
      case Field.BIRTHDATE:
        return Field.BIRTHDATE
      case Field.IS_ADMIN:
        return Field.IS_ADMIN
      case Field.MERCHANT_ID:
        return Field.MERCHANT_ID
      case Field.RATING:
        return Field.RATING
      case Field.COMMENT:
        return Field.COMMENT
      case Field.URL:
        return Field.URL
      case Field.CURRENCY:
        return Field.CURRENCY
      case Field.CATEGORY_ID:
        return Field.CATEGORY_ID
      case Field.STATE:
        return Field.STATE
      case Field.READY_WITHIN:
        return Field.READY_WITHIN
      case Field.DESCRIPTION:
        return Field.DESCRIPTION
      case Field.THUMBNAIL_URL:
        return Field.THUMBNAIL_URL
      case Field.ADDRESS:
        return Field.ADDRESS
      case Field.STREET:
        return Field.STREET
      case Field.BUILDING:
        return Field.BUILDING
      case Field.FLOOR:
        return Field.FLOOR
      case Field.POST_CODE:
        return Field.POST_CODE
      case Field.CITY_ID:
        return Field.CITY_ID
      case Field.LONGITUDE:
        return Field.LONGITUDE
      case Field.LATITUDE:
        return Field.LATITUDE
      case Field.HAS_WHATSAPP:
        return Field.HAS_WHATSAPP
      case Field.HAS_TELEGRAM:
        return Field.HAS_TELEGRAM
      case Field.PROFILE_PICTURE_URL:
        return Field.PROFILE_PICTURE_URL
      case Field.COVER_PHOTO_URL:
        return Field.COVER_PHOTO_URL
      case Field.BRIEF:
        return Field.BRIEF
      case Field.TAX_IDENTIFICATION_NUMBER:
        return Field.TAX_IDENTIFICATION_NUMBER
      case Field.DELIVERY_AVAILABLE:
        return Field.DELIVERY_AVAILABLE
      case Field.PICKUP_AVAILABLE:
        return Field.PICKUP_AVAILABLE
      case Field.PLATFORM:
        return Field.PLATFORM
      case Field.ISO_3LETTER:
        return Field.ISO_3LETTER
      case Field.ISO_2LETTER:
        return Field.ISO_2LETTER
      case Field.COUNTRY_ISO_3LETTER:
        return Field.COUNTRY_ISO_3LETTER
      case Field.MERCHANT_PROFILE_PICTURE_URL:
        return Field.MERCHANT_PROFILE_PICTURE_URL
      case Field.MERCHANT_COVER_PHOTO_URL:
        return Field.MERCHANT_COVER_PHOTO_URL
      case Field.MERCHANT_NAME:
        return Field.MERCHANT_NAME
      case Field.MERCHANT_BRIEF:
        return Field.MERCHANT_BRIEF
      case Field.MERCHANT_TAX_IDENTIFICATION_NUMBER:
        return Field.MERCHANT_TAX_IDENTIFICATION_NUMBER
      case Field.MERCHANT_DELIVERY_AVAILABLE:
        return Field.MERCHANT_DELIVERY_AVAILABLE
      case Field.MERCHANT_PICKUP_AVAILABLE:
        return Field.MERCHANT_PICKUP_AVAILABLE
      case Field.OLD_PASSWORD:
        return Field.OLD_PASSWORD
      case '':
        return Field.NONE;
      case null:
        return Field.NONE;
      default:
        return Field.UNKNOWN;
    }
  }

  private getEntity(entityName: string): Entity {
    switch (entityName) {
      case Entity.ADDRESS:
        return Entity.ADDRESS;
      case Entity.AREA:
        return Entity.AREA;
      case Entity.CATEGORY:
        return Entity.CATEGORY;
      case Entity.CITY:
        return Entity.CITY;
      case Entity.COUNTRY:
        return Entity.COUNTRY;
      case Entity.CURRENCY:
        return Entity.CURRENCY;
      case Entity.CUSTOMER_EMAIL:
        return Entity.CUSTOMER_EMAIL;
      case Entity.FORGOT_PASSWORD:
        return Entity.FORGOT_PASSWORD;
      case Entity.MERCHANT:
        return Entity.MERCHANT;
      case Entity.MERCHANT_GEOLOCATION:
        return Entity.MERCHANT_GEOLOCATION;
      case Entity.MERCHANT_IMAGE:
        return Entity.MERCHANT_IMAGE;
      case Entity.MERCHANT_PHONE:
        return Entity.MERCHANT_PHONE;
        case Entity.MERCHANT_RATING:
        return Entity.MERCHANT_RATING;
      case Entity.MERCHANT_SOCIAL_MEDIA_PLATFORM:
        return Entity.MERCHANT_SOCIAL_MEDIA_PLATFORM;
      case Entity.MERCHANT_WEBSITE:
        return Entity.MERCHANT_WEBSITE;
      case Entity.PRODUCT:
        return Entity.PRODUCT;
      case Entity.PRODUCT_IMAGE:
        return Entity.PRODUCT_IMAGE;
      case Entity.PRODUCT_RATING:
        return Entity.PRODUCT_RATING;
      case Entity.USER:
        return Entity.USER;
      case '':
        return Entity.NONE;
      case null:
        return Entity.NONE;
      default:
        return Entity.UNKNOWN;
    }
  }
}
