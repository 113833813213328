import { Component } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { Merchant } from "../../../../business/interfaces/Merchant";
import { User } from "../../../../business/interfaces/User";
import { ErrorHandling } from "../../../../common/classes/ErrorHandling";
import {
  ActionRow,
  ColumnFilterType, FilterTable,
  TableColumnInfo,
  TableConfiguration
} from "../../../../components/table-view/table-view.component";
import { ActivatedRoute } from "@angular/router";
import { AppNavigator, NavigatorTarget } from "../../../../services/app-navigator";
import { MerchantAPI } from "../../../../business/apis/MerchantAPI";
import { UserAPI } from "../../../../business/apis/UserAPI";
import { TableAction } from "../../../../components/table-view/enum/TableAction";
import { UserNameId } from "../../../../business/name-id/UserNameId";
import { format } from "date-fns";
import { UserFilterData } from "../../../../business/classes/UserFilterData";
import { FilterMetaData } from "../../../../common/classes/FilterMetaData";
import { ListUserResponse } from "../../../../business/interfaces/response-pagination/ListUserResponse";

@Component({
  selector: 'merchant-users',
  templateUrl: './merchant-users.component.html',
  styleUrls: ['./merchant-users.component.css']
})
export class MerchantUsersComponent {
  static navigatorName: string = NavigatorTarget.merchantUsers.valueOf();
  reloadData = new BehaviorSubject<boolean | null>(null);

  merchantDetails: Merchant | null = null;
  listUser: User[] = []
  errorHandling: ErrorHandling | null = null;

  tableConfiguration: TableConfiguration<User> = {};
  loadTableData: BehaviorSubject<[any, any]> = new BehaviorSubject<[any, any]>([[], null]);


  isScreenLoading: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appNavigator: AppNavigator,
    private merchantApi: MerchantAPI,
    private userApi: UserAPI
  ) {
  }

  ngOnInit(): void {
    let pagePagination = {
      show: true,
      list: [5, 10, 15, 20],
      selectedItemsPerPage: 15,
    };

    this.tableConfiguration = {
      pagePagination: pagePagination,
      tableColumnInfo: this.getColumTableInfo(),
      actionsRow: this.getActionRowTable.bind(this),
      getRowValue: this.getRowValue.bind(this),
      onCellTapped: this.onCellTapped.bind(this),
      loadData: this.loadTableData,
      reloadDataTable: this.reloadDataTable.bind(this),
    };

  }

  ngOnDestroy(): void {
    this.loadTableData?.unsubscribe();
  }


  loadMerchantDetails(data:any){
    this.merchantDetails = data;
    this.reloadDataSource()
    this.isScreenLoading = false;
  }

  getActionRowTable<T>(item: T): ActionRow[] {
    let user = item as User;

    let isDeleted = user.isDeleted ?? false;

    let deleteItem = {
      title: "Delete",
      type: "btn btn-danger",
      iconClass: "bi bi-trash",
      actionType: TableAction.REMOVE,
      onTapAction: this.onTapAction.bind(this),
    };

    let detailsItem = {
      title: "Details",
      type: "btn btn-primary",
      iconClass: "bi bi-info-circle",
      actionType: TableAction.DETAILS,
      onTapAction: this.onTapAction.bind(this),
    };

    let actionItems: [boolean, ActionRow][] = [
      [true, detailsItem],
      [!isDeleted, deleteItem],
    ];
    

    // Filter out tuples where the condition is false and map the remaining tuples to their respective ActionRow objects
    return actionItems
      .filter(([condition]: [boolean, ActionRow]) => condition)
      .map(([, actionRow]: [boolean, ActionRow]) => actionRow);
  }

  getColumTableInfo(): TableColumnInfo[] {

    let optionBool = [
      {
        value: true,
        label: "true",
        isSelected: false,
      },
      {
        value: false,
        label: "false",
        isSelected: false,
      },
    ];

    let idItem = {
      columId: UserNameId.ID,
      name: "Id",
      filterType: ColumnFilterType.text,
      isCellSelected: true,
    };

    let nameEn = {
      columId: UserNameId.NAME,
      name: "Name",
      filterType: ColumnFilterType.text,
    };

    let email = {
      columId: UserNameId.EMAIL,
      name: "Email",
      filterType: ColumnFilterType.text,
    };

    let countryCode = {
      columId: UserNameId.COUNTRY_CODE,
      name: "Country Code",
      filterType: ColumnFilterType.text,
    };

    let phoneNumber =
      {
        columId: UserNameId.PHONE_NUMBER,
        name: "Phone Number",
        filterType: ColumnFilterType.text,
      };

    let birthDate = {
      columId: UserNameId.BIRTHDATE,
      name: "Birth Date",
      filterType: ColumnFilterType.text,
    };


    let emailVerified = {
      columId: UserNameId.EMAIL_VERIFIED,
      name: "Email Verified",
      filterType: ColumnFilterType.select,
      listSelectedOption: optionBool,
    };

    let isAdminItem = {
      columId: UserNameId.IS_ADMIN,
      name: "Is Admin",
      filterType: ColumnFilterType.select,
      listSelectedOption: optionBool,
    };

    let isDeletedItem = {
      columId: UserNameId.IS_DELETED,
      name: "Is Deleted",
      filterType: ColumnFilterType.select,
      listSelectedOption: optionBool,
    };

    let createdAtItem = {
      columId: UserNameId.CREATED_AT,
      name: "Created",
      filterType: ColumnFilterType.dateRang,
    };


    return [
      idItem,
      nameEn,
      email,
      countryCode,
      phoneNumber,
      birthDate,
      isAdminItem,
      emailVerified,
      isDeletedItem,
      createdAtItem,
    ];
  }

  onCellTapped<T>(column: TableColumnInfo, item: T) {
    const id = column.columId;
    if (id == UserNameId.ID) {
      this.onTapUserDetails(item);
    }
  }

  getRowValue<T>(column: TableColumnInfo, item: T): any {
    const id = column.columId;
    let user = item as User;
    let value = user[id as keyof User];
    let styleClass = null;
    if (id == UserNameId.ID) {
      styleClass = "link-primary cursor-pointer";
    }

    if (
      column.filterType == ColumnFilterType.date ||
      column.filterType == ColumnFilterType.dateRang
    ) {
      const date = new Date(value as string);
      value = format(date, "MMM dd, yyyy, HH:mm:ss");
    }

    return {
      value: value,
      class: styleClass,
    };
  }

  navigateToCreateNewItem() {
    this.appNavigator.navigateTo({
      target: NavigatorTarget.userDetails,
      id: this.merchantDetails?.id,
    });
  }

  reloadDataSource(filterData?: FilterTable | null) {
    let filter = new UserFilterData();
    filter.filterFieldTable = filterData?.filterFieldTable ?? [];
    filter.filterMetaData = filterData?.filterMetaData ?? new FilterMetaData();
    filter.sortField = filterData?.sortFieldTable ?? null;
    this.isScreenLoading = true;
    if (this.merchantDetails == null) {
      return
    }

    let merchantId = this.merchantDetails.id ?? "";
    this.userApi.getListForMerchant({ filterData: filter, merchantId: merchantId}).subscribe({
      next: (response) => {
        this.reloadTable(response);
        this.isScreenLoading = false;
      },
      error: (error: any) => {
        // Handle error
        this.isScreenLoading = false;
        console.error("Error:", error);
      },
    });
  }

  private onTapAction<T>(action: any, item: T) {
    let userAction = action as TableAction;
    let user = item as User;
    let call: Observable<any>;
    if (userAction == TableAction.REMOVE) {
      let merchantId = this.merchantDetails?.id ?? ""
      call = this.userApi.delete({ user: user });
    }   else if (userAction == TableAction.DETAILS) {
      this.onTapUserDetails(item)
      return;
    }else {
      return;
    }

    this.isScreenLoading = true;
    call.subscribe({
      next: () => {
        this.reloadData!.next(true)
      },
      error: (error: any) => {
      },
    });
  }

  private onTapUserDetails<T>(item: T) {
    let user = item as User;
    this.appNavigator.navigateTo({
      target: NavigatorTarget.userDetails,
      id: user?.id,
    });
  }

  private reloadTable(response: ListUserResponse) {
    this.listUser = response.data;
    let metaData = response.meta;
    this.tableConfiguration.loadData?.next([this.listUser, metaData]);
  }

  private reloadDataTable<T>(filterData: FilterTable) {
    this.reloadDataSource(filterData);
  }
}

