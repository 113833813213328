import { Component, ViewChild } from '@angular/core';
import { AppNavigator, NavigatorTarget } from "../../services/app-navigator";
import { User } from "../../business/interfaces/User";
import { InputField } from "../interfaces/InputField";
import { FormGroup, Validators } from "@angular/forms";
import { ErrorHandling } from "../../common/classes/ErrorHandling";
import { ActivatedRoute } from "@angular/router";
import { UserAPI } from "../../business/apis/UserAPI";
import { BehaviorSubject, map } from "rxjs";
import { UserNameId } from "../../business/name-id/UserNameId";
import { getEnumFromValue } from "../../common/utilities/General";
import { isFormSubmitted } from "../../common/utilities/FormsHelper";
import { InputFieldType } from "../../components/input/enum/InputFieldType";
import { InputType } from "../../components/input/enum/InputType";
import { MobileNumberComponent } from "../../components/input/mobile-number/mobile-number.component";
import { OptionSelect } from "../../components/filters/enum/filter-type";
import { GenderHelper } from "../../business/enums/Gender";
import { AccountAPI } from "../../business/apis/AccountAPI";

@Component({
  selector: 'app-user-details',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent {
  static navigatorName: string = NavigatorTarget.editProfile.valueOf();

  userId: string = "";
  userDetails: User | null = null;
  userFields: InputField[] = [];
  form: FormGroup = new FormGroup({});
  errorHandling: ErrorHandling | null = null;
  isLoading = false;

  nameItem!: InputField;
  emailItem!: InputField;
  passwordItem!: InputField;
  birthDateItem!: InputField;
  isAdminItem!: InputField;
  genderItem!: InputField;
  merchantIdItem!: InputField;

  @ViewChild(MobileNumberComponent)
  mobileNumberField?: MobileNumberComponent;


  Validators = Validators
  userNameId = UserNameId

  constructor(
    private activatedRoute: ActivatedRoute,
    private userApi: UserAPI,
    private accountAPI: AccountAPI,
    private appNavigator: AppNavigator,
  ) {
  }

  ngOnInit(): void {
    this.userFields = this.getInputFields();
    this.reloadDataSource()
  }


  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {
  }

  getInputFieldById(id: UserNameId): InputField | undefined {
    let item = this.userFields.find((field) => field?.id == id);
    return item;
  }

  updateInputFieldById(id: string, value: any) {
    let fieldId = getEnumFromValue(UserNameId, id) as UserNameId
    let field = this.getInputFieldById(fieldId);
    if (field != undefined) {
      field.value = value;
    }
  }

  getValueFieldById(id: string): any {
    let fieldId = getEnumFromValue(UserNameId, id) as UserNameId
    let field = this.getInputFieldById(fieldId);
    return field?.value ?? null;
  }

  submit() {
    if (isFormSubmitted(this.form)) {
      this.updateUser();
    }
  }

  navigateBack(): void {
    this.appNavigator.back();
  }

  getListProductState(): OptionSelect[] {
    return GenderHelper.getListOption();
  }


  private updateUser() {
    let countryCodeValue =
      this.mobileNumberField?.selectedCountry?.countryCode ?? "";
    let phoneNumberValue = this.mobileNumberField?.mobileNumber ?? "";
    let gender = (this.getValueFieldById(UserNameId.GENDER) as OptionSelect)?.value ?? "";


    let user = {
      id: this.userDetails?.id,
      name: this.getValueFieldById(UserNameId.NAME) as string ?? "",
      email: this.getValueFieldById(UserNameId.EMAIL) as string ?? "",
      countryCode: countryCodeValue,
      phoneNumber: phoneNumberValue,
      gender: gender,
      birthDate: this.getValueFieldById(UserNameId.BIRTHDATE) as string ?? "",
    } as User;


    this.restError();
    this.isLoading = true;
    this.accountAPI.save({
      user: user,
    }).subscribe({
      next: (item) => {
        console.log(item);
        let userId = this.birthDateItem.value ?? "";
        this.userId = userId;
        this.navigateBack()
      },
      error: (error: any) => {
        this.checkError(error);
        this.isLoading = false;
      },
    });
  }

  private reloadDataSource() {
    this.accountAPI
      .getAccountInfo({ userId:"" })
      .subscribe({
        next: (item: User) => {
          // Handle successful data retrieval
          this.userDetails = item;
          this.reloadDataViews();
        },
        error: (error: any) => {
          // Handle error
          console.error("Error:", error);
          this.reloadDataViews();
        },
      });
  }

  private reloadDataViews() {
    this.getInputFieldById(UserNameId.NAME)?.loadData?.next(
      this.userDetails?.name
    );
    this.getInputFieldById(UserNameId.EMAIL)?.loadData?.next(
      this.userDetails?.email
    );
    this.getInputFieldById(UserNameId.BIRTHDATE)?.loadData?.next(
      this.userDetails?.birthDate
    );

    let countryCode = this.userDetails?.countryCode ?? "";
    if (countryCode?.hasActualValue()) {
      this.mobileNumberField?.updateViews([this.userDetails?.countryCode, this.userDetails?.phoneNumber]);
    } else {
      this.mobileNumberField?.updateCountrySelected("Egy");
    }

    let gender = GenderHelper.getOption(this.userDetails?.gender ?? "")
    this.getInputFieldById(
      UserNameId.GENDER
    )?.loadData?.next(gender);

  }

  private checkError(error: any) {
    let errorHandling = ErrorHandling.configurationError(error);
    this.errorHandling = errorHandling;
  }

  private restError() {
    this.errorHandling = null;
  }

  private getInputFields(): InputField[] {
    this.nameItem = {
      id: UserNameId.NAME,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "Name",
      validators: [Validators.required],
      value: "",
      loadData: new BehaviorSubject<any>(""),
    } as InputField;

    this.emailItem = {
      id: UserNameId.EMAIL,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "Email",
      validators: [Validators.required],
      loadData: new BehaviorSubject<any>(""),
    } as InputField;


    this.birthDateItem = {
      id: UserNameId.BIRTHDATE,
      inputFieldType: InputFieldType.textField,
      floatingLabel: "Birth Date",
      validators: [],
      loadData: new BehaviorSubject<any>(""),
    } as InputField;


    this.genderItem = {
      id: UserNameId.GENDER,
      floatingLabel: "Gender",
      validators: [],
      loadData: new BehaviorSubject<any>(null),
    } as InputField;

    return [
      this.nameItem,
      this.emailItem,
      this.birthDateItem,
      this.passwordItem,
      this.genderItem,
    ];
  }
}
