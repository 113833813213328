<app-merchant
  (onMerchantLoad)="loadMerchantDetails($event)"
  [loadData]=reloadData
  [title]="'Menu'"
>

  <!-- Menu -->
  <collapsible-header  [isOpen]=true title="Menu">
    <merchant-menus
      [loadData]="reloadMerchantMenu"
    ></merchant-menus>
  </collapsible-header>


  <div class="list-layout">
    <div class="header-action-item">
      <button (click)="navigateToCreateNewItem()" class="btn btn-secondary">
        <i class="bi bi-plus"></i>
      </button>
    </div>

    <table-view
      [isLoading]=isScreenLoading
      [tableConfiguration]="tableConfiguration">
    </table-view>

    <div *ngIf="errorHandling != null && errorHandling.errorMessage.hasActualValue()" class="alert alert-danger"
         role="alert">
      {{ errorHandling.errorMessage }}
    </div>
  </div>

</app-merchant>

