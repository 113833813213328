import { Component } from '@angular/core';
import { NavigatorTarget } from "../../../../services/app-navigator";
import { BehaviorSubject } from "rxjs";
import { Merchant } from "../../../../business/interfaces/Merchant";
import { ErrorHandling } from "../../../../common/classes/ErrorHandling";

@Component({
  selector: 'app-merchant-media',
  templateUrl: './merchant-media.component.html',
  styleUrls: ['./merchant-media.component.scss']
})
export class MerchantMediaComponent {
  static navigatorName: string = NavigatorTarget.merchantMedia.valueOf();

  reloadData = new BehaviorSubject<boolean | null>(null);//notify main parent to get Merchant
  loadData: BehaviorSubject<any> = new BehaviorSubject<any>(null); //notify children (image and video) after merchant loaded

  merchantDetails: Merchant | null = null;
  errorHandling: ErrorHandling | null = null;


  isScreenLoading: boolean = false;


  ngOnDestroy(): void {
    this.reloadData.unsubscribe()
    this.loadData.unsubscribe()
  }


  loadMerchantDetails(data: any) {
    this.merchantDetails = data;
    this.loadData?.next(data);
    this.isScreenLoading = false;
  }

  reloadMerchantDetails() {
    this.reloadData?.next(true);
  }
}
