import { Component } from '@angular/core';
import {AppNavigator, NavigatorTarget} from "../../services/app-navigator";
import {City} from "../../business/interfaces/City";
import {InputField} from "../interfaces/InputField";
import {FormGroup, Validators} from "@angular/forms";
import {ErrorHandling} from "../../common/classes/ErrorHandling";
import {ActivatedRoute} from "@angular/router";
import {CityAPI} from "../../business/apis/CityAPI";
import {BehaviorSubject, map} from "rxjs";
import {CityNameId} from "../../business/name-id/CityNameId";
import {getEnumFromValue} from "../../common/utilities/General";
import {isFormSubmitted} from "../../common/utilities/FormsHelper";
import {InputFieldType} from "../../components/input/enum/InputFieldType";
import {InputType} from "../../components/input/enum/InputType";
import {Country} from "../../business/interfaces/Country";

@Component({
  selector: 'app-city-details',
  templateUrl: './city-details.component.html',
  styleUrls: ['./city-details.component.scss']
})
export class CityDetailsComponent {

  static navigatorName: string = NavigatorTarget.cityDetails.valueOf();

  cityId: string = "";
  cityDetails: City | null = null;
  cityFields: InputField[] = [];
  form: FormGroup = new FormGroup({});
  errorHandling: ErrorHandling | null = null;
  isLoading = false;

  nameEnglishItem!: InputField;
  nameArabicItem!: InputField;
  countryItem!: InputField;


  constructor(
    private activatedRoute: ActivatedRoute,
    private cityApi: CityAPI,
    private appNavigator: AppNavigator,
  ) {
  }

  ngOnInit(): void {
    this.cityFields = this.getInputFields();

    this.activatedRoute.params
      .pipe(map((parameters) => parameters["id"]))
      .subscribe((id) => {
        this.cityId = id;
        this.reloadDataSource(
          () => {
          },
          () => {
          }
        );
      });

  }


  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {
  }

  getInputFieldById(id: CityNameId): InputField | undefined {
    let item = this.cityFields.find((field) => field.id === id);
    return item;
  }

  updateInputFieldById(id: string, value: any) {
    let fieldId = getEnumFromValue(CityNameId, id) as CityNameId
    let field = this.getInputFieldById(fieldId);
    if (field != undefined) {
      field.value = value;
    }
  }

  getValueFieldById(id: string): Object {
    let fieldId = getEnumFromValue(CityNameId, id) as CityNameId
    let field = this.getInputFieldById(fieldId);
    return field?.value ?? null;
  }

  submit() {
    if (isFormSubmitted(this.form)) {
      this.updateCity();
    }
  }

  updateCity() {
    let city = {
      id: this.cityDetails?.id ?? "",
      nameEn:
        this.getValueFieldById(CityNameId.NAME_EN)?.toStringValue() ?? "",
      nameAr:
        this.getValueFieldById(CityNameId.NAME_AR)?.toStringValue() ?? "",
      country: (this.countryItem.value as Country)?.iso3letter  ?? "",
    } as City;

    console.log(city);
    this.restError();
    this.isLoading = true;
    this.cityApi.save({city: city}).subscribe({
      next: (item) => {
        console.log(item);
        if (item.hasValue()) {
          this.cityId = item;
        }
        this.navigateBack()
      },
      error: (error: any) => {
        this.checkError(error);
        this.isLoading = false;
      },
    });
  }

  reloadDataSource(completionHandler: () => void, errorHandler: () => void) {
    if (this.cityId?.hasActualValue()) {
      this.cityApi
        .getDetails({cityId: this.cityId})
        .subscribe({
          next: (item: City) => {
            // Handle successful data retrieval
            this.cityDetails = item;
            this.reloadDataViews();
            errorHandler(); // Call the completion handler
          },
          error: (error: any) => {
            // Handle error
            console.error("Error:", error);
            this.reloadDataViews();
            errorHandler(); // Call the completion handler
          },
        });
    } else {
      this.reloadDataViews();
      completionHandler(); // Call the completion handler
    }
  }

  navigateBack(): void {
    this.appNavigator.back();
  }

  reloadDataViews() {
    this.getInputFieldById(CityNameId.NAME_EN)?.loadData?.next(
      this.cityDetails?.nameEn
    );
    this.getInputFieldById(CityNameId.NAME_AR)?.loadData?.next(
      this.cityDetails?.nameAr
    );
    this.getInputFieldById(CityNameId.COUNTRY_ISO3)?.loadData?.next(
      this.cityDetails?.country
    );

  }

  checkError(error: any) {
    let errorHandling = ErrorHandling.configurationError(error);
    this.errorHandling = errorHandling;
  }

  restError() {
    this.errorHandling = null;
  }

  private getInputFields(): InputField[] {
    this.nameEnglishItem = {
      id: CityNameId.NAME_EN,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "Name English",
      validators: [Validators.required],
      value: "",
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.nameArabicItem = {
      id: CityNameId.NAME_AR,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "Name Arabic",
      validators: [Validators.required],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.countryItem = {
      id: CityNameId.COUNTRY_ISO3,
      inputFieldType: InputFieldType.textField,
      floatingLabel: "Country",
      validators: [],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;



    return [
      this.nameEnglishItem,
      this.nameArabicItem,
      this.countryItem,
    ];
  }
}
