import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { Merchant } from "../../../../business/interfaces/Merchant";
import { ErrorHandling } from "../../../../common/classes/ErrorHandling";
import {
  ActionRow, ColumnFilterType,
  FilterTable,
  TableColumnInfo,
  TableConfiguration
} from "../../../../components/table-view/table-view.component";
import { ActivatedRoute } from "@angular/router";
import { AppNavigator, NavigatorTarget } from "../../../../services/app-navigator";
import { MerchantAPI } from "../../../../business/apis/MerchantAPI";
import { TableAction } from "../../../../components/table-view/enum/TableAction";
import { ProductNameId } from "../../../../business/name-id/ProductNameId";
import { Product } from "../../../../business/interfaces/Product";
import { ProductAPI } from "../../../../business/apis/ProductAPI";
import { format } from "date-fns";
import { ProductFilterData } from "../../../../business/classes/ProductFilterData";
import { FilterMetaData } from "../../../../common/classes/FilterMetaData";
import { ListProductResponse } from "../../../../business/interfaces/response-pagination/ListProductResponse";
import { CountryNameId } from "../../../../business/name-id/CountryNameId";

@Component({
  selector: 'merchant-products',
  templateUrl: './merchant-products.component.html',
  styleUrls: ['./merchant-products.component.css']
})
export class MerchantProductsComponent {

  static navigatorName: string = NavigatorTarget.merchantProduct.valueOf();
  reloadData = new BehaviorSubject<boolean | null>(null);
  reloadMerchantMenu = new BehaviorSubject<boolean | null>(null);

  merchantDetails: Merchant | null = null;
  listProduct: Product[] = []
  errorHandling: ErrorHandling | null = null;

  tableConfiguration: TableConfiguration<Product> = {};
  loadTableData: BehaviorSubject<[any, any]> = new BehaviorSubject<[any, any]>([[], null]);


  isScreenLoading: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appNavigator: AppNavigator,
    private merchantApi: MerchantAPI,
    private productApi: ProductAPI
  ) {
  }

  ngOnInit(): void {
    let pagePagination = {
      show: true,
      list: [5, 10, 15, 20],
      selectedItemsPerPage: 15,
    };

    this.tableConfiguration = {
      pagePagination: pagePagination,
      tableColumnInfo: this.getColumTableInfo(),
      actionsRow: this.getActionRowTable.bind(this),
      getRowValue: this.getRowValue.bind(this),
      onCellTapped: this.onCellTapped.bind(this),
      loadData: this.loadTableData,
      reloadDataTable: this.reloadDataTable.bind(this),
    };

  }

  ngOnDestroy(): void {
    this.loadTableData?.unsubscribe();
  }

  loadMerchantDetails(data:any){
    this.merchantDetails = data;
    this.reloadMerchantMenu?.next(data);
    this.reloadDataSource()
    this.isScreenLoading = false;
  }


  getActionRowTable<T>(item: T): ActionRow[] {
    let product = item as Product;

    let isDeleted = product.isDeleted ?? false;

    let deleteItem = {
      title: "Delete",
      type: "btn btn-danger",
      iconClass: "bi bi-trash",
      actionType: TableAction.REMOVE,
      onTapAction: this.onTapAction.bind(this),
    };

    let detailsItem = {
      title: "Details",
      type: "btn btn-primary",
      iconClass: "bi bi-info-circle",
      actionType: TableAction.DETAILS,
      onTapAction: this.onTapAction.bind(this),
    };

    let actionItems: [boolean, ActionRow][] = [
      [true, detailsItem],
      [!isDeleted, deleteItem],
    ];

    // Filter out tuples where the condition is false and map the remaining tuples to their respective ActionRow objects
    return actionItems
      .filter(([condition]: [boolean, ActionRow]) => condition)
      .map(([, actionRow]: [boolean, ActionRow]) => actionRow);
  }

  getColumTableInfo(): TableColumnInfo[] {

    let idItem = {
      columId: ProductNameId.ID,
      name: "Id",
      filterType: ColumnFilterType.text,
      isCellSelected: true,
    };

    let productSlugItem = {
      columId: ProductNameId.SLUG,
      name: "Product - Slug",
      filterType: ColumnFilterType.text,
    };

    let nameEn = {
      columId: ProductNameId.NAME_EN,
      name: "Name - English",
      filterType: ColumnFilterType.text,
    };

    let nameAr = {
      columId: ProductNameId.NAME_AR,
      name: "Name - Arabic",
      filterType: ColumnFilterType.text,
    };

    let descriptionEn = {
      columId: ProductNameId.DESCRIPTION_EN,
      name: "descriptionEn",
      filterType: ColumnFilterType.text,
    };

    let descriptionAr = {
      columId: ProductNameId.DESCRIPTION_AR,
      name: "descriptionEn",
      filterType: ColumnFilterType.text,
    };


    let categoryId = {
      columId: ProductNameId.CATEGORY_ID,
      name: "categoryId",
      filterType: ColumnFilterType.text,
    };

    let merchantId = {
      columId: ProductNameId.MERCHANT_ID,
      name: "Merchant Id",
      filterType: ColumnFilterType.text,
    };

    let state = {
      columId: ProductNameId.STATE,
      name: "State",
      filterType: ColumnFilterType.text,
    };

    let readyWithin = {
      columId: ProductNameId.READY_WITHIN,
      name: "Ready Within",
      filterType: ColumnFilterType.text,
    };

    let price = {
      columId: ProductNameId.PRICE,
      name: "Price",
      filterType: ColumnFilterType.number,
    };

    let currency = {
      columId: ProductNameId.CURRENCY,
      name: "Currency",
      filterType: ColumnFilterType.text,
    };


    let createdAtItem = {
      columId: CountryNameId.CREATED_AT,
      name: "Date",
      filterType: ColumnFilterType.dateRang,
    };

    return [
      idItem,
      productSlugItem,
      nameEn,
      nameAr,
      descriptionEn,
      descriptionAr,
      price,
      currency,
      state,
      readyWithin,
      categoryId,
      merchantId,
      createdAtItem,

    ];
  }

  onCellTapped<T>(column: TableColumnInfo, item: T) {
    const id = column.columId;
    if (id == ProductNameId.ID) {
      this.onTapProductDetails(item);
    }
  }

  getRowValue<T>(column: TableColumnInfo, item: T): any {
    const id = column.columId;
    let product = item as Product;
    let value = product[id as keyof Product];
    let styleClass = null;
    if (id == ProductNameId.ID) {
      styleClass = "link-primary cursor-pointer";
    }
    else if (
      column.filterType == ColumnFilterType.date ||
      column.filterType == ColumnFilterType.dateRang
    ) {
      const date = new Date(value as string);
      value = format(date, "MMM dd, yyyy, HH:mm:ss");
    }else  if(id == ProductNameId.PRICE){
      value = value?.toString().toDollars()
    }



    return {
      value: value,
      class: styleClass,
    };
  }

  navigateToCreateNewItem() {
    this.appNavigator.navigateTo({
      target: NavigatorTarget.productDetails,
      id: this.merchantDetails?.id,
    });
  }

  reloadDataSource(filterData?: FilterTable | null) {
    let filter = new ProductFilterData();
    filter.filterFieldTable = filterData?.filterFieldTable ?? [];
    filter.filterMetaData = filterData?.filterMetaData ?? new FilterMetaData();
    filter.sortField = filterData?.sortFieldTable ?? null;
    this.isScreenLoading = true;
    if (this.merchantDetails == null) {
      return
    }

    let merchantId = this.merchantDetails.id ?? "";
    this.productApi.getListForMerchant({ filterData: filter, merchantId: merchantId}).subscribe({
      next: (response) => {
        this.reloadTable(response);
        this.isScreenLoading = false;
      },
      error: (error: any) => {
        // Handle error
        this.isScreenLoading = false;
        console.error("Error:", error);
      },
    });
  }

  private onTapAction<T>(action: any, item: T) {
    let productAction = action as TableAction;
    let product = item as Product;
    let call: Observable<any>;
    if (productAction == TableAction.REMOVE) {
      let merchantId = this.merchantDetails?.id ?? ""
      call = this.productApi.delete({ product: product });
    }  else if (productAction == TableAction.DETAILS) {
      this.onTapProductDetails(item)
      return;
    }else {
      return;
    }

    this.isScreenLoading = true;
    call.subscribe({
      next: () => {
        this.reloadData!.next(true);
      },
      error: (error: any) => {
      },
    });
  }

  private onTapProductDetails<T>(item: T) {
    let product = item as Product;
    this.appNavigator.navigateTo({
      target: NavigatorTarget.productDetails,
      id: this.merchantDetails?.id,
      id2: product.id,
    });
  }

  private reloadTable(response: ListProductResponse) {
    this.listProduct = response.data;
    let metaData = response.meta;
    this.tableConfiguration.loadData?.next([this.listProduct, metaData]);
  }

  private reloadDataTable<T>(filterData: FilterTable) {
    this.reloadDataSource(filterData);
  }
}

