import { Params, Router } from "@angular/router";
import { Injectable } from '@angular/core';
import { Location } from "@angular/common";


export enum NavigatorTarget {
  home = '',
  login = 'login',
  dashboard = 'dashboard',
  profile = 'profile',
  categories = 'categories',
  categoryDetails = 'category',
  cities = 'cities',
  cityDetails = 'city',
  countries = 'countries',
  countryDetails = 'country',
  currencies = 'currencies',
  currencyDetails = 'currency',
  areas = 'areas',
  areaDetails = 'area',
  merchantAreaDetails = 'merchantArea',
  merchantAddressDetails = 'merchantAddress',
  merchantPhoneDetails = 'merchantPhoneDetails',
  merchantWebsiteDetails = 'merchantWebsiteDetails',
  merchantSocialMediaPlatformDetails = 'merchantSocialMediaPlatformDetails',
  merchantGeoLocationDetails = 'merchantGeoLocationDetails',
  productDetails = "product",
  userDetails = "user",


  merchantBasicInfo = "merchant-basic-info",
  merchantProduct = "menu",
  merchantUsers = "users",
  merchantMedia = "images&video",
  listMerchantAddress = "addresses",
  listMerchantWebsite = "websites",
  listMerchantSocialMedia = "socialMedia",
  listMerchantPhones = "phones",
  listGeoLocations = "geoLocations",
  listMerchantAreas = "areas",
  editProfile = "editProfile",
  changePassword = "changePassword",
  rateDetails = "rateDetails",
  listProductsRates =  "ListProductsRates",
  listMerchantRates =  "listMerchantRates"
}


@Injectable({ providedIn: 'root' })
export class AppNavigator {

  constructor(
    private router: Router,
    private location: Location
  ) {

  }


  navigateTo(parameters: { target: NavigatorTarget, id?: string, id2?: string, parameter?: any, queryParams?: Params }) {
    const name = parameters.target.valueOf()

    let path = `/${name}`
    if (parameters.id !== undefined) {
      path = `${path}/${parameters.id}`
    }
    if (parameters.id2 !== undefined) {
      path = `${path}/${parameters.id2}`
    }

    if (parameters.parameter !== null && parameters.parameter !== undefined) {
      this.router.navigate([path, parameters.parameter]).then();
    } else {
      this.router.navigate([path]).then();
    }
  }

  back() {
    this.location.back()
  }


  url(): string {
    return this.router.url
  }

  queryParams(): Params {
    const queryParams = this.router.routerState.snapshot.root.queryParams;
    return queryParams;
  }
}
