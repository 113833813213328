import { Component } from '@angular/core';
import { AppNavigator, NavigatorTarget } from "../../services/app-navigator";
import { User } from "../../business/interfaces/User";
import { UserDatabase } from "../../business/database/user-database.service";
import { MerchantService } from "../../services/merchant.service";
import { LoginAPI } from "../../business/apis/LoginAPI";
import { SidebarService } from "../../services/sidebar.service";

@Component({
  selector: 'app-header', templateUrl: './header.component.html', styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  currentUser: User | null = null

  constructor(private userDatabase: UserDatabase,
              private appNavigator: AppNavigator,
              public merchantService: MerchantService,
              private loginAPI : LoginAPI,
              private sidebarService: SidebarService) {
  }

  ngOnInit(): void {
    this.loginAPI.updateUserAuthentication()
    this.currentUser = this.userDatabase.getCurrentUser();
  }

  logOut() {
    this.userDatabase.removeCurrentUser()
    this.appNavigator.navigateTo({ target: NavigatorTarget.login });
  }

  editProfile() {
    this.appNavigator.navigateTo({target: NavigatorTarget.editProfile});
  }

  changePassword() {
    this.appNavigator.navigateTo({target: NavigatorTarget.changePassword});
  }

  showUserStatus(): boolean {
    let merchant = this.merchantService.merchant
    if(merchant){
      return !(merchant?.isApproved ?? false)
    }
    return  false;
  }

  toggleSidebar() {
    console.log("Toggling sidebar")
    this.sidebarService.toggleSidebar();
  }
}
