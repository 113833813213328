import { Injectable } from "@angular/core";
import { APIClientService } from "../../services/apiclient.service";
import { catchError, Observable, tap, throwError } from "rxjs";
import { APIType } from "../../common/enums/APIType";
import { RequestType } from "../../common/enums/RequestType";
import { CountryFilterData } from "../classes/CountryFilterData";
import { Country } from "../interfaces/Country";
import { ListCountriesResponse } from "../interfaces/response-pagination/ListCountriesResponse";
import { compare } from "../../common/utilities/General";


@Injectable({ providedIn: 'root' })
export class CountryAPI {
  constructor(private readonly apiClient: APIClientService) {

  }


  save(parameters: {
    id: string
    country: Country,
    oldCountry?: Country | null,
  }): Observable<string> {

    let id = parameters.id;
    let dataUrl = id?.hasActualValue() ? `/${id}` : "";
    let country = parameters.country;
    let old = parameters.oldCountry;
    let iso3letter = compare(old?.iso3letter ?? "", country.iso3letter);
    let iso2letter = compare(old?.iso2letter ?? "", country.iso2letter);

    let request = {
      nameEn: country.nameEn,
      nameAr: country.nameAr,
      iso3letter: iso3letter,
      iso2letter: iso2letter,
      countryCode: country.countryCode,
    }


    return this.apiClient.call<any, string>({
      apiType: APIType.ADD_COUNTRY,
      requestType: RequestType.POST,
      body: request,
      dataUrl,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }

  getList(filterData: CountryFilterData): Observable<ListCountriesResponse> {
    let parameter = CountryFilterData.getParameterApi(filterData);
    return this.apiClient.call<any, ListCountriesResponse>({
      apiType: APIType.GET_LIST_COUNTRIES,
      body: parameter,
      requestType: RequestType.GET,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }


  getDetails(parameters: {
    countryId: string,
  }): Observable<Country> {
    let dataUrl = parameters.countryId;
    return this.apiClient.call<any, Country>({
      apiType: APIType.GET_COUNTRY_DETAILS,
      requestType: RequestType.GET,
      dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }
}
