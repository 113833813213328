import {Component} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {Merchant} from "../../../../business/interfaces/Merchant";
import {ErrorHandling} from "../../../../common/classes/ErrorHandling";
import {
  ActionRow,
  FilterTable,
  TableColumnInfo,
  TableConfiguration
} from "../../../../components/table-view/table-view.component";
import {GeoLocation} from "../../../../business/interfaces/GeoLocation";
import {ActivatedRoute} from "@angular/router";
import {AppNavigator, NavigatorTarget} from "../../../../services/app-navigator";
import {MerchantAPI} from "../../../../business/apis/MerchantAPI";
import {TableAction} from "../../../../components/table-view/enum/TableAction";
import {GeoLocationNameId} from "../../../../business/name-id/GeoLocationNameId";

@Component({
  selector: 'merchant-geoLocations',
  templateUrl: './merchant-geo-locations.component.html',
  styleUrls: ['./merchant-geo-locations.component.css']
})
export class MerchantGeoLocationsComponent {
  static navigatorName: string = NavigatorTarget.listGeoLocations.valueOf();
  reloadData = new BehaviorSubject<boolean | null>(null);

  merchantDetails: Merchant | null = null;
  errorHandling: ErrorHandling | null = null;

  tableConfiguration: TableConfiguration<Geolocation> = {};
  loadTableData: BehaviorSubject<[any, any]> = new BehaviorSubject<[any, any]>([[], null]);


  isScreenLoading: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appNavigator: AppNavigator,
    private merchantApi: MerchantAPI
  ) {
  }

  ngOnInit(): void {
    this.tableConfiguration = {
      hasFilter: false,
      tableColumnInfo: this.getColumTableInfo(),
      actionsRow: this.getActionRowTable.bind(this),
      getRowValue: this.getRowValue.bind(this),
      onCellTapped: this.onCellTapped.bind(this),
      loadData: this.loadTableData,
      reloadDataTable: this.reloadDataTable.bind(this),
    };

  }

  ngOnDestroy(): void {
    this.loadTableData?.unsubscribe();
  }


  loadMerchantDetails(data:any){
    this.merchantDetails = data;
    this.reloadDataViews()
    this.isScreenLoading = false;
  }



  reloadDataViews() {
    let list = this.merchantDetails?.geoLocations ?? [];
    this.tableConfiguration.loadData?.next([list, null]);
  }


  getActionRowTable<T>(item: T): ActionRow[] {
    let deleteItem = {
      title: "Delete",
      type: "btn btn-danger",
      iconClass: "bi bi-trash",
      actionType: TableAction.REMOVE,
      onTapAction: this.onTapAction.bind(this),
    };
    return [deleteItem]
  }


  getColumTableInfo(): TableColumnInfo[] {
    let longitude = {
      columId: GeoLocationNameId.LONGITUDE,
      name: "Longitude",
    };

    let latitude = {
      columId: GeoLocationNameId.LATITUDE,
      name: "Latitude",
    };

    return [
      longitude,
      latitude,
    ];
  }

  onCellTapped<T>(column: TableColumnInfo, item: T) {
    const id = column.columId;
    if (id == GeoLocationNameId.ID) {
      this.onTapGeolocationDetails(item);
    }
  }

  getRowValue<T>(column: TableColumnInfo, item: T): any {
    const id = column.columId;
    let geolocation = item as Geolocation;
    let value = geolocation[id as keyof Geolocation];
    let styleClass = null;

    return {
      value: value,
      class: styleClass,
    };
  }

  navigateToCreateNewItem() {
    this.appNavigator.navigateTo({
      target: NavigatorTarget.merchantGeoLocationDetails,
      id: this.merchantDetails?.id,
    });
  }


  private onTapAction<T>(action: any, item: T) {
    let geoLocationAction = action as TableAction;
    let geoLocation = item as GeoLocation;
    let call: Observable<any>;

    if (geoLocationAction == TableAction.REMOVE) {
      let merchantId = this.merchantDetails?.id ?? ""
      let GeolocationId = geoLocation.id
      call = this.merchantApi.deleteGeoLocation({merchantId: merchantId, geoLocationId: GeolocationId});
    } else {
      return;
    }

    this.isScreenLoading = true;
    call.subscribe({
      next: () => {
        this.reloadData!.next(true);
      },
      error: (error: any) => {
      },
    });
  }

  private onTapGeolocationDetails<T>(item: T) {
    let Geolocation = item as Geolocation;
  }

  private reloadDataTable<T>(filterData: FilterTable) {
  }
}
