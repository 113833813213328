import {Component} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {Merchant} from "../../../../business/interfaces/Merchant";
import {ErrorHandling} from "../../../../common/classes/ErrorHandling";
import {
  ActionRow,
  FilterTable,
  TableColumnInfo,
  TableConfiguration
} from "../../../../components/table-view/table-view.component";
import {Website} from "../../../../business/interfaces/Website";
import {ActivatedRoute} from "@angular/router";
import {AppNavigator, NavigatorTarget} from "../../../../services/app-navigator";
import {MerchantAPI} from "../../../../business/apis/MerchantAPI";
import {TableAction} from "../../../../components/table-view/enum/TableAction";
import {WebsiteNameId} from "../../../../business/name-id/WebsiteNameId";

@Component({
  selector: 'merchant-websites',
  templateUrl: './merchant-websites.component.html',
  styleUrls: ['./merchant-websites.component.css']
})
export class MerchantWebsitesComponent {

  static navigatorName: string = NavigatorTarget.listMerchantWebsite.valueOf();
  reloadData = new BehaviorSubject<boolean | null>(null);


  merchantDetails: Merchant | null = null;


  errorHandling: ErrorHandling | null = null;

  tableConfiguration: TableConfiguration<Website> = {};
  loadTableData: BehaviorSubject<[any, any]> = new BehaviorSubject<[any, any]>([[], null]);


  isScreenLoading: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appNavigator: AppNavigator,
    private merchantApi: MerchantAPI
  ) {
  }

  ngOnInit(): void {
    this.tableConfiguration = {
      hasFilter: false,
      tableColumnInfo: this.getColumTableInfo(),
      actionsRow: this.getActionRowTable.bind(this),
      getRowValue: this.getRowValue.bind(this),
      onCellTapped: this.onCellTapped.bind(this),
      loadData: this.loadTableData,
      reloadDataTable: this.reloadDataTable.bind(this),
    };
  }

  ngOnDestroy(): void {
    this.loadTableData?.unsubscribe();
  }


  loadMerchantDetails(data:any){
    this.merchantDetails = data;
    this.reloadDataViews()
    this.isScreenLoading = false;
  }


  reloadDataViews() {
    let list = this.merchantDetails?.websites ?? [];
    this.tableConfiguration.loadData?.next([list, null]);
  }

  getActionRowTable<T>(item: T): ActionRow[] {
    let deleteItem = {
      title: "Delete",
      type: "btn btn-danger",
      iconClass: "bi bi-trash",
      actionType: TableAction.REMOVE,
      onTapAction: this.onTapAction.bind(this),
    };
    return [deleteItem]
  }


  getColumTableInfo(): TableColumnInfo[] {
    let URL = {
      columId: WebsiteNameId.URL,
      name: "Url",
    };
    return [
      URL,
    ];
  }

  onCellTapped<T>(column: TableColumnInfo, item: T) {
    const id = column.columId;
    if (id == WebsiteNameId.ID) {
      this.onTapWebsiteDetails(item);
    }
  }

  getRowValue<T>(column: TableColumnInfo, item: T): any {
    const id = column.columId;
    let website = item as Website;
    let value = website[id as keyof Website];
    let styleClass = null;

    return {
      value: value,
      class: styleClass,
    };
  }

  navigateToCreateNewItem() {
    this.appNavigator.navigateTo({
      target: NavigatorTarget.merchantWebsiteDetails,
      id: this.merchantDetails?.id,
    });
  }


  private onTapAction<T>(action: any, item: T) {
    let WebsiteAction = action as TableAction;
    let Website = item as Website;
    let call: Observable<any>;

    if (WebsiteAction == TableAction.REMOVE) {
      let merchantId = this.merchantDetails?.id ?? ""
      let WebsiteId = Website.id
      call = this.merchantApi.deleteWebsite({merchantId: merchantId, websiteId: WebsiteId});
    } else {
      return;
    }

    this.isScreenLoading = true;
    call.subscribe({
      next: () => {
        this.reloadData!.next(true)
      },
      error: (error: any) => {
      },
    });
  }

  private onTapWebsiteDetails<T>(item: T) {
    let Website = item as Website;
  }

  private reloadDataTable<T>(filterData: FilterTable) {
  }
}
