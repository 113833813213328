import { Injectable } from "@angular/core";
import { APIClientService } from "../../services/apiclient.service";
import { catchError, Observable, tap, throwError } from "rxjs";
import { JWTToken } from "../interfaces/JWTToken";
import { APIType } from "../../common/enums/APIType";
import { UserDatabase } from "../database/user-database.service";
import {RequestType} from "../../common/enums/RequestType";


@Injectable({providedIn: 'root'})
export class LoginAPI {
  constructor(private readonly apiClient: APIClientService,
              private userDatabase: UserDatabase) {

  }

  login(parameters: {
    email: string,
    password: string,
  }): Observable<JWTToken> {
    let request = {
      email: parameters.email,
      password: parameters.password,
    }

    return this.apiClient.call<any, JWTToken>({
      apiType: APIType.LOGIN,
      body: request,
      requestType: RequestType.POST
    }).pipe(
      tap(response => {
        console.log(response);
        this.userDatabase.saveJWTToken({jWTToken: response});
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }

  updateUserAuthentication(){
    this.apiClient.updateUserAuthentication()
  }
}
