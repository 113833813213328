import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from "./pages/login/login.component";
import { MasterComponent } from "./template/master/master.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { MerchantComponent } from "./pages/merchant/merchant.component";
import { LoginGuard } from "./guards/login.guard";
import { CategoryDetailsComponent } from "./pages/category-details/category-details.component";
import { CityDetailsComponent } from "./pages/city-details/city-details.component";
import { CountryDetailsComponent } from "./pages/country-details/country-details.component";
import { AreaDetailsComponent } from "./pages/area-details/area-details.component";
import { MerchantAreaDetailsComponent } from "./pages/merchant-area-details/merchant-area-details.component";
import { MerchantAddressDetailsComponent } from "./pages/merchant-address-details/merchant-address-details.component";
import { MerchantPhoneDetailsComponent } from "./pages/merchant-phone-details/merchant-phone-details.component";
import { MerchantWebsiteDetailsComponent } from "./pages/merchant-website-details/merchant-website-details.component";
import {
  MerchantSocialMediaPlatformDetailsComponent
} from "./pages/merchant-social-media-platform-details/merchant-social-media-platform-details.component";
import {
  MerchantGeoLocationDetailsComponent
} from "./pages/merchant-geo-location-details/merchant-geo-location-details.component";
import { ProductDetailsComponent } from "./pages/product-details/product-details.component";
import { UserDetailsComponent } from "./pages/user-details/user-details.component";
import {
  MerchantBasicInfoComponent
} from "./pages/merchant/pages/merchant-basic-info/merchant-basic-info.component";
import { MerchantProductsComponent } from "./pages/merchant/pages/merchant-products/merchant-products.component";
import { MerchantUsersComponent } from "./pages/merchant/pages/merchant-users/merchant-users.component";
import { MerchantMediaComponent } from "./pages/merchant/pages/merchant-media/merchant-media.component";
import { MerchantAddressComponent } from "./pages/merchant/pages/merchant-address/merchant-address.component";
import { MerchantWebsitesComponent } from "./pages/merchant/pages/merchant-websites/merchant-websites.component";
import {
  MerchantSocialMediaPlatformsComponent
} from "./pages/merchant/pages/merchant-social-media-platforms/merchant-social-media-platforms.component";
import { MerchantPhonesComponent } from "./pages/merchant/pages/merchant-phones/merchant-phones.component";
import {
  MerchantGeoLocationsComponent
} from "./pages/merchant/pages/merchant-geo-locations/merchant-geo-locations.component";
import { MerchantAreasComponent } from "./pages/merchant/pages/merchant-areas/merchant-areas.component";
import { EditProfileComponent } from "./pages/edit-profile/edit-profile.component";
import { ChangePasswordComponent } from "./pages/change-password/change-password.component";
import { ListProductsRatesComponent } from "./pages/list-products-rates/list-products-rates.component";
import { ListMerchantsRatesComponent } from "./pages/list-merchants-rates/list-merchants-rates.component";


const routes: Routes = [
  {
    path: LoginComponent.navigatorName,
    component: LoginComponent,
  },
  {
    path: LoginComponent.navigatorName+ "/:JWTToken",
    component: LoginComponent,
  },
  {
    path: '',
    component: MasterComponent,
    children: [

      //new merchant paged
      { path: '', component: MerchantBasicInfoComponent },
      {
        path: MerchantBasicInfoComponent.navigatorName,
        component: MerchantBasicInfoComponent,
      },
      {
        path: MerchantProductsComponent.navigatorName,
        component: MerchantProductsComponent,
      },
      {
        path: MerchantMediaComponent.navigatorName,
        component: MerchantMediaComponent,
      },
      {
        path: MerchantAddressComponent.navigatorName,
        component: MerchantAddressComponent,
      },
      {
        path: MerchantWebsitesComponent.navigatorName,
        component: MerchantWebsitesComponent,
      },
      {
        path: MerchantSocialMediaPlatformsComponent.navigatorName,
        component: MerchantSocialMediaPlatformsComponent,
      },
      {
        path: MerchantPhonesComponent.navigatorName,
        component: MerchantPhonesComponent,
      },
      {
        path: MerchantGeoLocationsComponent.navigatorName,
        component: MerchantGeoLocationsComponent,
      },
      {
        path: MerchantAreasComponent.navigatorName,
        component: MerchantAreasComponent,
      },

      {
        path: MerchantUsersComponent.navigatorName,
        component: MerchantUsersComponent,
      },

      {
        path: DashboardComponent.navigatorName,
        component: DashboardComponent,
      },
      {
        path: CategoryDetailsComponent.navigatorName + "/:id",
        component: CategoryDetailsComponent,
      },
      {
        path: CategoryDetailsComponent.navigatorName,
        component: CategoryDetailsComponent,
      },

      {
        path: CityDetailsComponent.navigatorName + "/:id",
        component: CityDetailsComponent,
      },
      {
        path: CityDetailsComponent.navigatorName,
        component: CityDetailsComponent,
      },

      {
        path: CountryDetailsComponent.navigatorName + "/:id",
        component: CountryDetailsComponent,
      },
      {
        path: CountryDetailsComponent.navigatorName,
        component: CountryDetailsComponent,
      },
      {
        path: AreaDetailsComponent.navigatorName,
        component: AreaDetailsComponent,
      },
      {
        path: AreaDetailsComponent.navigatorName + "/:id",
        component: AreaDetailsComponent,
      },

      {
        path: MerchantAreaDetailsComponent.navigatorName,
        component: MerchantAreaDetailsComponent,
      },
      {
        path: MerchantAreaDetailsComponent.navigatorName + "/:merchantId",
        component: MerchantAreaDetailsComponent,
      },

      {
        path: MerchantAddressDetailsComponent.navigatorName,
        component: MerchantAddressDetailsComponent,
      },
      {
        path: MerchantAddressDetailsComponent.navigatorName + "/:merchantId",
        component: MerchantAddressDetailsComponent,
      },
      {
        path: MerchantAddressDetailsComponent.navigatorName + "/:merchantId/:id",
        component: MerchantAddressDetailsComponent,
      },

      {
        path: MerchantPhoneDetailsComponent.navigatorName,
        component: MerchantPhoneDetailsComponent,
      },
      {
        path: MerchantPhoneDetailsComponent.navigatorName + "/:merchantId",
        component: MerchantPhoneDetailsComponent,
      },
      {
        path: MerchantPhoneDetailsComponent.navigatorName + "/:merchantId/:id",
        component: MerchantPhoneDetailsComponent,
      },


      {
        path: MerchantWebsiteDetailsComponent.navigatorName,
        component: MerchantWebsiteDetailsComponent,
      },
      {
        path: MerchantWebsiteDetailsComponent.navigatorName + "/:merchantId",
        component: MerchantWebsiteDetailsComponent,
      },
      {
        path: MerchantWebsiteDetailsComponent.navigatorName + "/:merchantId/:id",
        component: MerchantWebsiteDetailsComponent,
      },


      {
        path: MerchantSocialMediaPlatformDetailsComponent.navigatorName,
        component: MerchantSocialMediaPlatformDetailsComponent,
      },
      {
        path: MerchantSocialMediaPlatformDetailsComponent.navigatorName + "/:merchantId",
        component: MerchantSocialMediaPlatformDetailsComponent,
      },
      {
        path: MerchantWebsiteDetailsComponent.navigatorName + "/:merchantId/:id",
        component: MerchantSocialMediaPlatformDetailsComponent,
      },

      {
        path: MerchantGeoLocationDetailsComponent.navigatorName,
        component: MerchantGeoLocationDetailsComponent,
      },
      {
        path: MerchantGeoLocationDetailsComponent.navigatorName + "/:merchantId",
        component: MerchantGeoLocationDetailsComponent,
      },
      {
        path: MerchantGeoLocationDetailsComponent.navigatorName + "/:merchantId/:id",
        component: MerchantGeoLocationDetailsComponent,
      },
      {
        path: ProductDetailsComponent.navigatorName + "/:merchantId",
        component: ProductDetailsComponent,
      },
      {
        path: ProductDetailsComponent.navigatorName + "/:merchantId/:id",
        component: ProductDetailsComponent,
      },
      {
        path: UserDetailsComponent.navigatorName + "/:id",
        component: UserDetailsComponent,
      },
      {
        path: UserDetailsComponent.navigatorName,
        component: UserDetailsComponent,
      },
      {
        path: EditProfileComponent.navigatorName,
        component: EditProfileComponent,
      },  {
        path: ChangePasswordComponent.navigatorName,
        component: ChangePasswordComponent,
      },
      {
        path: ListMerchantsRatesComponent.navigatorName,
        component: ListMerchantsRatesComponent,
      },
      {
        path: ListProductsRatesComponent.navigatorName,
        component: ListProductsRatesComponent,
      },


    ],
    canActivate: [LoginGuard],
    runGuardsAndResolvers: 'always'
  }
];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
  providers: [
    LoginGuard,
  ]
})

export class AppRoutingModule {
}
