<nav aria-label="breadcrumb" style="--bs-breadcrumb-divider: '>';">
  <ol class="breadcrumb">
    <li *ngIf="merchantId.hasActualValue()" class="breadcrumb-item"><a (click)="navigateToMerchant()" class="link-primary">Merchant</a>
    </li>
    <li *ngIf="productDetails" aria-current="page" class="breadcrumb-item active">{{productDetails.nameEn}}</li>
    <li *ngIf="!productDetails" aria-current="page" class="breadcrumb-item active">Add</li>
  </ol>
</nav>
<!-- Show this title only if myObject is null -->
<h1 *ngIf="!productDetails" class="h3 mb-3"><strong>Add</strong> Product</h1>
<!-- Show this title only if myObject exists (not null) -->
<h1 *ngIf="productDetails" class="h3 mb-3"><strong>Update</strong> Product</h1>
<!-- product-basic-info -->
<collapsible-header [isOpen]=true title="Info">
  <product-basic-info
    (reloadData)="getProduct($event)"
    [loadData]="loadBasicInfoData"
    [merchantId]="merchantId"
  ></product-basic-info>
</collapsible-header>


<div *ngIf="productDetails && !(productDetails?.isDeleted ?? false)">
  <collapsible-header [isOpen]=true title="Images">
    <product-images
      [loadData]="loadBasicInfoData"
    ></product-images>
  </collapsible-header>

  <collapsible-header [isOpen]=true title="Videos">
    <product-videos
      (reloadData)="getProduct($event)"
      [loadData]="loadBasicInfoData"
    ></product-videos>
  </collapsible-header>


</div>




