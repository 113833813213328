
export enum RateNameId {
  id = 'id',
  merchantId = "merchantId",
  productId = "productId",
  rating = 'rating',
  comment = 'comment',
  commenter = 'commenter',
  commenterId = ' commenter.id',
  commenterName = 'commenter.name',
  reply = "reply",
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  isApproved = 'isApproved',
  isRejected = 'isRejected',
  isRepliedTo = 'isRepliedTo',
  isReplyDeleted = 'isReplyDeleted',
  isDeleted = 'isDeleted',
}




