import { Component, Input } from '@angular/core';
import { AppNavigator, NavigatorTarget } from "../../services/app-navigator";
import { UserDatabase } from "../../business/database/user-database.service";
import { User } from "../../business/interfaces/User";


export enum NavigationItem {
  DASHBOARD = "dashboard",

  MERCHANT_BASIC_INFO = "basicInfo",
  MERCHANT_PRODUCT = "product",
  USERS = "users",
  MERCHANT_MEDIA = "images&video",
  LIST_MERCHANT_ADDRESS = "addresses",
  LIST_MERCHANT_WEBSITE = "websites",
  LIST_MERCHANT_SOCIAL_MEDIA = "socialMedia",
  LIST_MERCHANT_PHONES = "phones",
  LIST_MERCHANT_GEOLOCATIONS = "geolocations",
  LIST_MERCHANT_AREAS = "areas",
  productsRates = "productsRates",
  merchantRates = "merchantRates",
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})


export class SidebarComponent {
  @Input() selectedItem: NavigationItem | null = NavigationItem.MERCHANT_BASIC_INFO;
  currentUser: User | null = null;
  protected readonly NavigationItem = NavigationItem;

  constructor(private appNavigator: AppNavigator, private userDatabase: UserDatabase) {
  }

  ngOnInit(): void {
    console.log('Component initialized');
    this.currentUser = this.userDatabase.getCurrentUser()
    const cleanedUrl = this.appNavigator.url().replace(/^\/+/, '');
    this.selectedItem = this.getNavigationItem(cleanedUrl);
  }

  getNavigationItem(target: string): NavigationItem {
    const foundItem = Object.values(NavigationItem).find(item => item === target);
    let item = foundItem as NavigationItem ?? this.selectedItem;
    return item;
  }

  navigateTo(item: NavigationItem) {
    this.selectedItem = item;
    if (item == NavigationItem.MERCHANT_BASIC_INFO) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.merchantBasicInfo })
    } else if (item == NavigationItem.MERCHANT_PRODUCT) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.merchantProduct })
    } else if (item == NavigationItem.DASHBOARD) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.dashboard })
    } else if (item == NavigationItem.USERS) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.merchantUsers })
    } else if (item == NavigationItem.MERCHANT_MEDIA) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.merchantMedia })
    } else if (item == NavigationItem.LIST_MERCHANT_ADDRESS) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.listMerchantAddress })
    } else if (item == NavigationItem.LIST_MERCHANT_WEBSITE) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.listMerchantWebsite })
    } else if (item == NavigationItem.LIST_MERCHANT_SOCIAL_MEDIA) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.listMerchantSocialMedia })
    } else if (item == NavigationItem.LIST_MERCHANT_PHONES) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.listMerchantPhones })
    } else if (item == NavigationItem.LIST_MERCHANT_GEOLOCATIONS) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.listGeoLocations })
    } else if (item == NavigationItem.LIST_MERCHANT_AREAS) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.listMerchantAreas })
    } else if (item == NavigationItem.productsRates) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.listProductsRates })
    } else if (item == NavigationItem.merchantRates) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.listMerchantRates })
    }
  }
}
