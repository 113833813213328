import { Component, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { Merchant } from "../../../../business/interfaces/Merchant";
import { ErrorHandling } from "../../../../common/classes/ErrorHandling";
import {
  ActionRow,
  FilterTable,
  TableColumnInfo,
  TableConfiguration
} from "../../../../components/table-view/table-view.component";
import { Phone } from "../../../../business/interfaces/Phone";
import { ActivatedRoute } from "@angular/router";
import { AppNavigator, NavigatorTarget } from "../../../../services/app-navigator";
import { MerchantAPI } from "../../../../business/apis/MerchantAPI";
import { TableAction } from "../../../../components/table-view/enum/TableAction";
import { PhoneNameId } from "../../../../business/name-id/PhoneNameId";
import { VerifyMobileNumberComponent } from "../verify-mobile-number/verify-mobile-number.component";

@Component({
  selector: 'merchant-phones',
  templateUrl: './merchant-phones.component.html',
  styleUrls: ['./merchant-phones.component.css']
})
export class MerchantPhonesComponent {

  static navigatorName: string = NavigatorTarget.listMerchantPhones.valueOf();
  reloadData = new BehaviorSubject<boolean | null>(null);


  merchantDetails: Merchant | null = null;
  errorHandling: ErrorHandling | null = null;

  tableConfiguration: TableConfiguration<Phone> = {};
  loadTableData: BehaviorSubject<[any, any]> = new BehaviorSubject<[any, any]>([[], null]);


  isScreenLoading: boolean = false;


  @ViewChild(VerifyMobileNumberComponent)
  verifyMobileNumberComponent?: VerifyMobileNumberComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appNavigator: AppNavigator,
    private merchantApi: MerchantAPI
  ) {
  }

  ngOnInit(): void {
    this.tableConfiguration = {
      hasFilter: false,
      tableColumnInfo: this.getColumTableInfo(),
      actionsRow: this.getActionRowTable.bind(this),
      getRowValue: this.getRowValue.bind(this),
      onCellTapped: this.onCellTapped.bind(this),
      loadData: this.loadTableData,
      reloadDataTable: this.reloadDataTable.bind(this),
    };

  }

  ngOnDestroy(): void {
    this.loadTableData?.unsubscribe();
  }


  loadMerchantDetails(data: any) {
    this.merchantDetails = data;
    this.reloadDataViews()
    this.isScreenLoading = false;
  }


  reloadDataViews() {
    let list = this.merchantDetails?.phones ?? [];
    this.tableConfiguration.loadData?.next([list, null]);
  }


  getActionRowTable<T>(item: T): ActionRow[] {
    let phone = item as Phone;
    let isVerified = phone.isVerified ?? false;

    let deleteItem = {
      title: "Delete",
      type: "btn btn-danger",
      iconClass: "bi bi-trash",
      actionType: TableAction.REMOVE,
      onTapAction: this.onTapAction.bind(this),
    };

    let approveItem = {
      title: "Verify",
      type: "btn btn-success",
      iconClass: "bi bi-check-lg",
      actionType: TableAction.APPROVE,
      onTapAction: this.onTapAction.bind(this),
    };


    let actionItems: [boolean, ActionRow][] = [
      [true, deleteItem],
      // [!isVerified, approveItem],
    ];

    // Filter out tuples where the condition is false and map the remaining tuples to their respective ActionRow objects
    return actionItems
      .filter(([condition]: [boolean, ActionRow]) => condition)
      .map(([, actionRow]: [boolean, ActionRow]) => actionRow);
  }


  getColumTableInfo(): TableColumnInfo[] {

    let id = {
      columId: PhoneNameId.ID,
      name: "Id",
    };


    let countryCode = {
      columId: PhoneNameId.COUNTRY_CODE,
      name: "Country Code",
    };

    let phoneNumber = {
      columId: PhoneNameId.PHONE_NUMBER,
      name: "Phone Number",
    };


    let hasWhatsapp = {
      columId: PhoneNameId.HAS_WHATSAPP,
      name: "Has Whatsapp",
    };

    let hasTelegram = {
      columId: PhoneNameId.HAS_TELEGRAM,
      name: "Has Telegram",
    };


    return [
      id,
      countryCode,
      phoneNumber,
      hasWhatsapp,
      hasTelegram,
    ];
  }

  onCellTapped<T>(column: TableColumnInfo, item: T) {
    const id = column.columId;
    if (id == PhoneNameId.ID) {
      this.onTapPhoneDetails(item);
    }
  }

  getRowValue<T>(column: TableColumnInfo, item: T): any {
    const id = column.columId;
    let phone = item as Phone;
    let value = phone[id as keyof Phone];
    let styleClass = null;

    return {
      value: value,
      class: styleClass,
    };
  }

  navigateToCreateNewItem() {
    this.appNavigator.navigateTo({
      target: NavigatorTarget.merchantPhoneDetails,
      id: this.merchantDetails?.id,
    });
  }


  private onTapAction<T>(action: any, item: T) {
    let PhoneAction = action as TableAction;
    let phone = item as Phone;
    let call: Observable<any>;

    if (PhoneAction == TableAction.REMOVE) {
      let merchantId = this.merchantDetails?.id ?? ""
      let PhoneId = phone.id
      call = this.merchantApi.deletePhone({ merchantId: merchantId, phoneId: PhoneId });

      this.isScreenLoading = true;
      call.subscribe({
        next: () => {
          this.reloadData!.next(true);
        },
        error: (error: any) => {
        },
      });

    }
    if (PhoneAction == TableAction.APPROVE) {
      // let merchantId = this.merchantDetails?.id ?? ""
      // let PhoneId = phone.id
      // this.verifyMobileNumberComponent?.showScreen({ phone: phone, merchant: this.merchantDetails! })
      //
      // this.verifyMobileNumberComponent?.onSuccess.subscribe(() => {
      //   this.reloadData!.next();
      // });
      return;
    } else {
      // return;
    }


  }

  private onTapPhoneDetails<T>(item: T) {
    let Phone = item as Phone;
    // this.appNavigator.navigateTo({
    //   target: NavigatorTarget.PhoneDetails,
    //   id: Phone.id,
    // });
  }

  private reloadDataTable<T>(filterData: FilterTable) {
  }
}

