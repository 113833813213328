import { Component } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { Merchant } from "../../../../business/interfaces/Merchant";
import { ActivatedRoute } from "@angular/router";
import { AppNavigator, NavigatorTarget } from "../../../../services/app-navigator";
import { MerchantAPI } from "../../../../business/apis/MerchantAPI";
import {
  ActionRow, ColumnFilterType, FilterTable,
  TableColumnInfo,
  TableConfiguration
} from "../../../../components/table-view/table-view.component";
import { Area } from "../../../../business/interfaces/Area";
import { TableAction } from "../../../../components/table-view/enum/TableAction";
import { AreaNameId } from "../../../../business/name-id/AreaNameId";
import { ErrorHandling } from "../../../../common/classes/ErrorHandling";
import { CityAPI } from "../../../../business/apis/CityAPI";
import { City } from "../../../../business/interfaces/City";
import { CityNameId } from "../../../../business/name-id/CityNameId";
import { CityMiniObjectNameId } from "../../../../business/name-id/MiniObjectNameId";

@Component({
  selector: 'merchant-areas',
  templateUrl: './merchant-areas.component.html',
  styleUrls: ['./merchant-areas.component.scss']
})
export class MerchantAreasComponent {
  static navigatorName: string = NavigatorTarget.listMerchantAreas.valueOf();
  reloadData = new BehaviorSubject<boolean | null>(null);


  merchantDetails: Merchant | null = null;
  errorHandling: ErrorHandling | null = null;

  tableConfiguration: TableConfiguration<Area> = {};
  loadTableData: BehaviorSubject<[any, any]> = new BehaviorSubject<[any, any]>([[], null]);


  isScreenLoading: boolean = false;

  listCities: City[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private appNavigator: AppNavigator,
    private merchantApi: MerchantAPI,
    private cityApi: CityAPI
  ) {
  }

  ngOnInit(): void {
    this.tableConfiguration = {
      hasFilter: false,
      tableColumnInfo: this.getColumTableInfo(),
      actionsRow: this.getActionRowTable.bind(this),
      getRowValue: this.getRowValue.bind(this),
      onCellTapped: this.onCellTapped.bind(this),
      loadData: this.loadTableData,
      reloadDataTable: this.reloadDataTable.bind(this),
    };

    this.getAllCities();
  }

  ngOnDestroy(): void {
    this.loadTableData?.unsubscribe();
  }

  loadMerchantDetails(data: any) {
    this.merchantDetails = data;
    this.reloadDataViews()
    this.isScreenLoading = false;
  }

  reloadDataViews() {
    let list = this.merchantDetails?.deliveryAreas ?? [];
    this.tableConfiguration.loadData?.next([list, null]);
  }

  getActionRowTable<T>(item: T): ActionRow[] {
    let deleteItem = {
      title: "Delete",
      type: "btn btn-danger",
      iconClass: "bi bi-trash",
      actionType: TableAction.REMOVE,
      onTapAction: this.onTapAction.bind(this),
    };
    return [deleteItem]
  }

  getColumTableInfo(): TableColumnInfo[] {

    let nameEn = {
      columId: AreaNameId.NAME_EN,
      name: "Name - English",

    };

    let nameAr = {
      columId: AreaNameId.NAME_AR,
      name: "Name - Arabic",
    };



    let cityId = {
      columId: CityMiniObjectNameId.ID,
      name: "City - Id",
      filterType: ColumnFilterType.text,
    };

    let cityNameEn = {
      columId: CityMiniObjectNameId.NAME_EN,
      name: "City - English",
      filterType: ColumnFilterType.text,
    };

    let cityNameAr = {
      columId: CityMiniObjectNameId.NAME_AR,
      name: "City - Arabic",
      filterType: ColumnFilterType.text,
    };

    let country = {
      columId: CityMiniObjectNameId.COUNTRY,
      name: "Country",
      filterType: ColumnFilterType.text,
    };

    return [
      country,
      cityId,
      cityNameAr,
      cityNameEn,
      cityNameEn,
      cityNameAr,
      nameEn,
      nameAr];
  }

  onCellTapped<T>(column: TableColumnInfo, item: T) {
    const id = column.columId;
    if (id == AreaNameId.ID) {
      this.onTapAreaDetails(item);
    }
  }

  getRowValue<T>(column: TableColumnInfo, item: T): any {
    const id = column.columId;
    let area = item as Area;
    let value = area[id as keyof Area];
    let styleClass = null;

   if(id == CityMiniObjectNameId.COUNTRY){
      value = area.city.country
    }
    else if(id == CityMiniObjectNameId.ID){
      value = area.city.id
    }
    else if(id == CityMiniObjectNameId.NAME_EN){
      value = area.city.nameEn
    }
    else if(id == CityMiniObjectNameId.NAME_AR){
      value = area.city.nameAr
    }


    return {
      value: value,
      class: styleClass,
    };
  }

  navigateToCreateNewItem() {
    this.appNavigator.navigateTo({
      target: NavigatorTarget.merchantAreaDetails,
      id: this.merchantDetails?.id,
    });
  }

  private getAllCities() {
    this.cityApi.getAll().subscribe({
      next: (response) => {
        this.listCities = response.data
      },
      error: (_) => {
      },
    });
  }

  private getCityById(id: string): City | null {
    let item = this.listCities.find((item) => item.id === id);
    return item ?? null;
  }

  private onTapAction<T>(action: any, item: T) {
    let areaAction = action as TableAction;
    let area = item as Area;
    let call: Observable<any>;

    if (areaAction == TableAction.REMOVE) {
      let merchantId = this.merchantDetails?.id ?? ""
      let areaId = area.id
      call = this.merchantApi.deleteArea({ merchantId: merchantId, areaId: areaId });
    } else {
      return;
    }

    this.isScreenLoading = true;
    call.subscribe({
      next: () => {
        this.reloadData!.next(true);
      },
      error: (error: any) => {
      },
    });
  }

  private onTapAreaDetails<T>(item: T) {
    let area = item as Area;
    // this.appNavigator.navigateTo({
    //   target: NavigatorTarget.areaDetails,
    //   id: area.id,
    // });
  }

  private reloadDataTable<T>(filterData: FilterTable) {
  }
}


