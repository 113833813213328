import { Component, AfterViewInit, Input, Output, EventEmitter } from "@angular/core";
import { AppNavigator, NavigatorTarget } from "../../services/app-navigator";
import { BehaviorSubject } from "rxjs";
import { Merchant } from "../../business/interfaces/Merchant";
import { ActivatedRoute } from "@angular/router";
import { MerchantAPI } from "../../business/apis/MerchantAPI";
import { ErrorHandling } from "../../common/classes/ErrorHandling";
import { UserDatabase } from "../../business/database/user-database.service";
import { MerchantService } from "../../services/merchant.service";


@Component({
  selector: "app-merchant",
  templateUrl: "./merchant.component.html",
  styleUrls: ["./merchant.component.scss"],
})
export class MerchantComponent implements AfterViewInit {
  merchantDetails: Merchant | null = null;

  @Input() title: string = "";

  @Input() loadData?: BehaviorSubject<boolean | null>;
  @Output() onMerchantLoad: EventEmitter<any> = new EventEmitter<any>();

  merchantId: string = "";
  errorHandling: ErrorHandling | null = null;
  isLoading = false


  constructor(
    private activatedRoute: ActivatedRoute,
    private appNavigator: AppNavigator,
    private merchantApi: MerchantAPI,
    private userDatabase: UserDatabase,
    public merchantService: MerchantService,
  ) {

  }

  ngOnInit(): void {

    let user = this.userDatabase.getCurrentUser()
    let merchantId = user?.merchantId ?? "";
    if (merchantId.hasActualValue()) {
      this.merchantId = merchantId;
      // this.reloadDataSource();

      this.loadData!.subscribe((data) => {
        if (data) {
          this.reloadDataSource();
        }
      })

      this.merchantService.isAuthenticationUpdated().subscribe(value => {
        if (value) {
          this.reloadDataSource();
        }
      });


    }


  }

  ngAfterViewInit(): void {
  }


  reloadDataSource() {
    if (this.merchantId?.hasActualValue()) {
      this.merchantApi
        .getDetails({ merchantId: this.merchantId })
        .subscribe({
          next: (item: Merchant) => {
            // Handle successful data retrieval
            this.merchantDetails = item;
            this.reloadDataViews();
          },
          error: (error: any) => {
            // Handle error
            console.error("Error:", error);
            this.reloadDataViews();
          },
        });
    } else {
      this.reloadDataViews();
    }
  }


  checkError(error: any) {
    let errorHandling = ErrorHandling.configurationError(error);
    this.errorHandling = errorHandling;
  }

  restError() {
    this.errorHandling = null;
  }

  private reloadDataViews() {
    this.onMerchantLoad.emit(this.merchantDetails)
  }

}
