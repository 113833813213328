<app-merchant
  (onMerchantLoad)="loadMerchantDetails($event)"
  [loadData]=reloadData
  [title]="'Images & Videos'"
>


  <!-- images -->
  <collapsible-header [isOpen]=true title="Images">
    <merchant-images
      [loadData]="loadData"
    ></merchant-images>
  </collapsible-header>

  <!-- video -->
  <collapsible-header [isOpen]=true title="Videos">
    <merchant-videos
      (reloadData)="reloadMerchantDetails()"
      [loadData]="loadData"
    ></merchant-videos>x
  </collapsible-header>

</app-merchant>
