
<ng-container>
  <h1 class="h3 mb-3"><strong>Update</strong> {{title}}</h1>
</ng-container>

<ng-content></ng-content>
<!-- merchant-basic-info -->

<!--<collapsible-header [isOpen]=true title="Basic Info">-->
<!--  <merchant-basic-info (reloadData)="getMerchant($event)"-->
<!--                       [loadData]="loadBasicInfoData"></merchant-basic-info>-->
<!--</collapsible-header>-->

<!--<div *ngIf="merchantDetails && !merchantDetails.isDeleted?.toBool()">-->

<!--  &lt;!&ndash; images &ndash;&gt;-->
<!--  <collapsible-header  [isOpen]=false title="Images">-->
<!--    <merchant-images-->
<!--      [loadData]="loadBasicInfoData"-->
<!--    ></merchant-images>-->
<!--  </collapsible-header>-->

<!--  &lt;!&ndash; video &ndash;&gt;-->
<!--  <collapsible-header  [isOpen]=false title="Videos">-->
<!--    <merchant-videos-->
<!--      (reloadData)="getMerchant($event)"-->
<!--      [loadData]="loadBasicInfoData"-->
<!--    ></merchant-videos>-->
<!--  </collapsible-header>-->


<!--  &lt;!&ndash; products &ndash;&gt;-->
<!--  <collapsible-header [isOpen]=false title="Products">-->
<!--    <merchant-products (reloadData)="getMerchant($event)" [loadData]="loadBasicInfoData"></merchant-products>-->
<!--  </collapsible-header>-->

<!--  &lt;!&ndash; products &ndash;&gt;-->
<!--  <collapsible-header [isOpen]=false title="Users">-->
<!--    <merchant-users (reloadData)="getMerchant($event)" [loadData]="loadBasicInfoData"></merchant-users>-->
<!--  </collapsible-header>-->



<!--  &lt;!&ndash; Areas &ndash;&gt;-->
<!--  <collapsible-header [isOpen]=false title="Areas">-->
<!--    <merchant-areas (reloadData)="getMerchant($event)" [loadData]="loadBasicInfoData"></merchant-areas>-->
<!--  </collapsible-header>-->

<!--  &lt;!&ndash; Address &ndash;&gt;-->
<!--  <collapsible-header [isOpen]=false title="Address">-->
<!--    <merchant-address (reloadData)="getMerchant($event)" [loadData]="loadBasicInfoData"></merchant-address>-->
<!--  </collapsible-header>-->

<!--  &lt;!&ndash; Phones &ndash;&gt;-->
<!--  <collapsible-header [isOpen]=false title="Phones">-->
<!--    <merchant-phones (reloadData)="getMerchant($event)" [loadData]="loadBasicInfoData"></merchant-phones>-->
<!--  </collapsible-header>-->

<!--  &lt;!&ndash; Websites &ndash;&gt;-->
<!--  <collapsible-header [isOpen]=false title="Websites">-->
<!--    <merchant-websites (reloadData)="getMerchant($event)" [loadData]="loadBasicInfoData"></merchant-websites>-->
<!--  </collapsible-header>-->

<!--  &lt;!&ndash;  social-media-platforms &ndash;&gt;-->
<!--  <collapsible-header [isOpen]=false title="Social Media Platforms">-->
<!--    <merchant-social-media-platforms (reloadData)="getMerchant($event)" [loadData]="loadBasicInfoData"></merchant-social-media-platforms>-->
<!--  </collapsible-header>-->


<!--  &lt;!&ndash;  geoLocations &ndash;&gt;-->
<!--  <collapsible-header [isOpen]=false title="GeoLocations">-->
<!--    <merchant-geoLocations (reloadData)="getMerchant($event)" [loadData]="loadBasicInfoData"></merchant-geoLocations>-->
<!--  </collapsible-header>-->


<!--</div>-->

<!--<div>-->
<!--  <pre>{{ merchantDetails | json }}</pre>-->
<!--</div>-->
