import { Component, ElementRef, EventEmitter, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MerchantAPI } from "../../../../business/apis/MerchantAPI";
import { isFormSubmitted } from "../../../../common/utilities/FormsHelper";
import { ValidationErrorType } from "../../../../common/enums/ValidationErrorType";
import { timer } from "rxjs";
import { Phone } from "../../../../business/interfaces/Phone";
import { Merchant } from "../../../../business/interfaces/Merchant";

@Component({
  selector: 'verify-mobile-number',
  templateUrl: './verify-mobile-number.component.html',
  styleUrls: ['./verify-mobile-number.component.scss']
})
export class VerifyMobileNumberComponent {
  @ViewChild('mobileNumberVerificationModal') modal!: ElementRef;

  public mobileNumberVerificationForm: FormGroup = new FormGroup({})

  public resendTimerSeconds = 30
  public disableResendVerificationButton = true

  public mobileNumberVerified = false

  public mobileNumberErrorMessage: string | null = null
  public loadingButton = false
  public verifyMobileNumberSuccessMessage: string | null = null
  public errorMessage: string | null = null


  private phone: Phone | null = null
  private merchant: Merchant | null = null

  @Output() onSuccess: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private renderer: Renderer2,
    private merchantApi: MerchantAPI,
  ) {
  }

  get digit1() {
    return this.mobileNumberVerificationForm.get('digit1');
  }

  get digit2() {
    return this.mobileNumberVerificationForm.get('digit2');
  }

  get digit3() {
    return this.mobileNumberVerificationForm.get('digit3');
  }

  get digit4() {
    return this.mobileNumberVerificationForm.get('digit4');
  }

  get digit5() {
    return this.mobileNumberVerificationForm.get('digit5');
  }

  get digit6() {
    return this.mobileNumberVerificationForm.get('digit6');
  }

  ngOnInit() {
    this.mobileNumberVerificationForm = this.formBuilder.group({
      digit1: ['', [Validators.required, Validators.min(0), Validators.max(9)]],
      digit2: ['', [Validators.required, Validators.min(0), Validators.max(9)]],
      digit3: ['', [Validators.required, Validators.min(0), Validators.max(9)]],
      digit4: ['', [Validators.required, Validators.min(0), Validators.max(9)]],
      digit5: ['', [Validators.required, Validators.min(0), Validators.max(9)]],
      digit6: ['', [Validators.required, Validators.min(0), Validators.max(9)]]
    });
  }


  showScreen(parameters: { phone: Phone, merchant: Merchant }) {
    this.merchant = parameters.merchant;
    this.phone = parameters.phone
    this.openModal()

  }

  onPINCodeKeyup(event: KeyboardEvent, nextInput?: HTMLInputElement, previousInput?: HTMLInputElement): void {
    const input = event.target as HTMLInputElement;
    if (event.key === 'Backspace') {
      if (previousInput) {
        if (input.value.length === 0) {
          previousInput.focus();
        }
      }
    } else {

      if (input.value.length === 1 && nextInput) {
        nextInput.focus();
      }
    }
  }

  resendMobileNumberVerificationCode(showLoading?: boolean) {
    this.startResendEnablementTimer()

    let countryCode = this.phone?.countryCode!
    let mobileNumber = this.phone?.phoneNumber!
    let merchantId = this.merchant?.id!

    if (countryCode && mobileNumber) {
      if (showLoading) {
        this.loadingButton = true
      }
      this.merchantApi.resendMerchantPhoneSMS({
        merchantId: merchantId, countryCode: countryCode, phoneNumber: mobileNumber
      }).subscribe({
        next: () => {
          this.loadingButton = false
          this.verifyMobileNumberSuccessMessage = `PIN code resend request sent successfully.`
        },
        error: error => {
          this.resetMobileNumberErrorMessages()
          let errorMessage = ""
          if (Array.isArray(error)) {
            // Handle each error separately
            console.log("error is an array")
            error.map(singleError => {
              console.log(singleError.errorType)
              console.log(singleError.field)
            });
          } else {
            errorMessage = `Something went wrong. Please try again later or contact support.`
          }
          if (showLoading) {
            this.errorMessage = errorMessage
          }

        },
      })
    }
  }

  submitMobileNumberVerificationForm() {
    let merchantId = this.merchant?.id!
    if (isFormSubmitted(this.mobileNumberVerificationForm) && this.digit1 && this.digit2 && this.digit3 && this.digit4 && this.digit5 && this.digit6) {
      // let token = this.digit1.concat(this.digit1 + this.digit2+ this.digit3 + this.digit4 + this.digit5 + this.digit6
      let token = this.digit1.value + "" + this.digit2.value + "" + this.digit3.value + "" + this.digit4.value + "" + this.digit5.value + "" + this.digit6.value
      this.loadingButton = true
      this.merchantApi.verifyMerchantPhone({ token: token, merchantId }).subscribe({
        next: () => {
          this.loadingButton = false
          this.onSuccess.emit()
          this.closeModal();
          this.resetPinCode()

        },
        error: error => {
          this.loadingButton = false
          this.resetMobileNumberErrorMessages()
          this.errorMessage = error.toString()
          if (Array.isArray(error)) {

            let errorText = error.toString()
            error.map(singleError => {
              console.log(singleError.errorType)
              console.log(singleError.field)
              if (singleError?.errorType == ValidationErrorType.TOKEN_MISMATCH){
                errorText = `Incorrect PIN code`
              }
              else {
                errorText = `Something went wrong. Please try again later or contact support.`
              }
              this.errorMessage = errorText
            })

          } else {

            // TODO: Check if type of error is string before printing
            let errorText = ""
            if (error == ValidationErrorType.UNAUTHORIZED) {
              errorText = `UNAUTHORIZED`
            } else if (error == ValidationErrorType.TOKEN_MISMATCH)
              errorText = `Incorrect PIN code`
            else
              errorText = `Something went wrong. Please try again later or contact support.`
            this.errorMessage = errorText
          }

        },
      })
    }
  }


  openModal() {
    this.renderer.addClass(this.modal.nativeElement, 'show');
    this.renderer.setStyle(this.modal.nativeElement, 'display', 'block');
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
    this.resendMobileNumberVerificationCode()
  }

  closeModal() {
    this.renderer.removeClass(this.modal.nativeElement, 'show');
    this.renderer.setStyle(this.modal.nativeElement, 'display', 'none');
    this.renderer.removeStyle(document.body, 'overflow');
  }

  onContainerClicked(event: MouseEvent): void {
    if ((event.target as Element).classList.contains('modal')) {
      this.closeModal();
    }
  }

  private resetMobileNumberErrorMessages() {
    this.errorMessage = null
  }


  private startResendEnablementTimer() {
    this.resendTimerSeconds = 30

    const countdownTimer = timer(1000, 1000).subscribe(elapsed => {
      this.resendTimerSeconds--;
      if (this.resendTimerSeconds === 0) {
        countdownTimer.unsubscribe();
        this.disableResendVerificationButton = false
      }
    });
  }


  resetPinCode() {
    this.digit1?.setValue("")
    this.digit1?.markAsPristine({ onlySelf: true });
    this.digit1?.markAsUntouched({ onlySelf: true });

    this.digit2?.setValue("")
    this.digit2?.markAsPristine({ onlySelf: true });
    this.digit2?.markAsUntouched({ onlySelf: true });

    this.digit3?.setValue("")
    this.digit3?.markAsPristine({ onlySelf: true });
    this.digit3?.markAsUntouched({ onlySelf: true });

    this.digit4?.setValue("")
    this.digit4?.markAsPristine({ onlySelf: true });
    this.digit4?.markAsUntouched({ onlySelf: true });

    this.digit5?.setValue("")
    this.digit5?.markAsPristine({ onlySelf: true });
    this.digit5?.markAsUntouched({ onlySelf: true });

    this.digit6?.setValue("")
    this.digit6?.markAsPristine({ onlySelf: true });
    this.digit6?.markAsUntouched({ onlySelf: true });

  }
}
