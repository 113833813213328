import {environment} from "../../../environments/environment";
import {APIType} from "../enums/APIType";

export class APIURLExtension {
  static version = 'v1';
  static serverURL = environment.apiUrl
  static fullServerURL = `${APIURLExtension.serverURL}/${APIURLExtension.version}`;

  static merchantServerURL = `${APIURLExtension.serverURL}/${APIURLExtension.version}/merchant`;
  static userServerURL = `${APIURLExtension.serverURL}/${APIURLExtension.version}/user`;
  static categoryServerURL = `${APIURLExtension.serverURL}/${APIURLExtension.version}/category`;
  static cityServerURL = `${APIURLExtension.serverURL}/${APIURLExtension.version}/city`;
  static countryServerURL = `${APIURLExtension.serverURL}/${APIURLExtension.version}/country`;
  static areaServerURL = `${APIURLExtension.serverURL}/${APIURLExtension.version}/area`;
  static currencyServerURL = `${APIURLExtension.serverURL}/${APIURLExtension.version}/currency`;
  static productServerURL = `${APIURLExtension.serverURL}/${APIURLExtension.version}/product`;
  static productImageServerURL = `${APIURLExtension.serverURL}/${APIURLExtension.version}/product-image`;
  static getFirebaseTokenServerURL = `${APIURLExtension.serverURL}/${APIURLExtension.version}/account/firebaseToken`;
  static accountServerURL = `${APIURLExtension.serverURL}/${APIURLExtension.version}/account`;
  static rateServerURL = `${APIURLExtension.serverURL}/${APIURLExtension.version}/rating`;


  static getURL(apiType: APIType): string {
    const serverURL = APIURLExtension.fullServerURL;
    switch (apiType) {
      case APIType.REFRESH_TOKEN:
        return `${APIURLExtension.accountServerURL}/refreshToken`
      case APIType.LOGIN:
        return `${serverURL}/merchant/login`;
      case APIType.REGISTER:
        return `${serverURL}/merchant/register`;


      case APIType.addMerchant:
        return `${APIURLExtension.merchantServerURL}{}`;
      case APIType.getMerchantDetails:
        return `${APIURLExtension.merchantServerURL}/{}`;
      case APIType.ADD_MERCHANT_AREA:
        return `${APIURLExtension.merchantServerURL}/{}/area`;
      case APIType.DELETE_MERCHANT_AREA:
        return `${APIURLExtension.merchantServerURL}/{}/area/{}`;
      case APIType.ADD_MERCHANT_ADDRESS:
        return `${APIURLExtension.merchantServerURL}/{}/address`;
      case APIType.DELETE_MERCHANT_ADDRESS:
        return `${APIURLExtension.merchantServerURL}/{}/address/{}`;
      case APIType.ADD_MERCHANT_PHONE:
        return `${APIURLExtension.merchantServerURL}/{}/phone`;

      case APIType.resendMerchantVerificationSMSCode:
        return `${APIURLExtension.merchantServerURL}/{}/phone/verify/{}`;
      case APIType.verifyMerchantCode:
        return `${APIURLExtension.merchantServerURL}/{}/phone/verify/{}`;

      case APIType.DELETE_MERCHANT_PHONE:
        return `${APIURLExtension.merchantServerURL}/{}/phone/{}`;
      case APIType.ADD_MERCHANT_WEBSITE:
        return `${APIURLExtension.merchantServerURL}/{}/website`;
      case APIType.DELETE_MERCHANT_WEBSITE:
        return `${APIURLExtension.merchantServerURL}/{}/website/{}`;
      case APIType.ADD_MERCHANT_SOCIAL_MEDIA_PLATFORM:
        return `${APIURLExtension.merchantServerURL}/{}/socialMediaPlatform`;
      case APIType.DELETE_MERCHANT_SOCIAL_MEDIA_PLATFORM:
        return `${APIURLExtension.merchantServerURL}/{}/socialMediaPlatform/{}`;
      case APIType.ADD_MERCHANT_GEOLOCATION:
        return `${APIURLExtension.merchantServerURL}/{}/geolocation`;
      case APIType.DELETE_MERCHANT_GEOLOCATION:
        return `${APIURLExtension.merchantServerURL}/{}/geolocation/{}`

      case APIType.ADD_MERCHANT_PRODUCT:
        return `${APIURLExtension.merchantServerURL}/{}/product`;
      case APIType.Get_MERCHANT_PRODUCTS:
        return `${APIURLExtension.merchantServerURL}/{}/product`;
      case APIType.Get_MERCHANT_Users:
        return `${APIURLExtension.merchantServerURL}/{}/user`;
      case APIType.ADD_MERCHANT_IMAGE:
        return `${APIURLExtension.merchantServerURL}/{}/image`;
      case APIType.DELETE_MERCHANT_IMAGE:
        return `${APIURLExtension.merchantServerURL}/{}/image/{}`;

      case APIType.ADD_MENU_ITEM:
        return `${APIURLExtension.merchantServerURL}/{}/menu-item`;
      case APIType.DELETE_MENU_ITEM:
        return `${APIURLExtension.merchantServerURL}/{}/menu-item/{}`;


      case APIType.ADD_CATEGORY:
        return `${APIURLExtension.categoryServerURL}{}`;
      case APIType.GET_LIST_CATEGORIES:
        return `${APIURLExtension.categoryServerURL}`;
      case APIType.GET_CATEGORY_DETAILS:
        return `${APIURLExtension.categoryServerURL}/{}`;

      case APIType.ADD_CITY:
        return `${APIURLExtension.cityServerURL}{}`;
      case APIType.GET_LIST_CITIES:
        return `${APIURLExtension.cityServerURL}`;
      case APIType.GET_CITY_DETAILS:
        return `${APIURLExtension.cityServerURL}/{}`;

      case APIType.ADD_COUNTRY:
        return `${APIURLExtension.countryServerURL}{}`;
      case APIType.GET_LIST_COUNTRIES:
        return `${APIURLExtension.countryServerURL}`;
      case APIType.GET_COUNTRY_DETAILS:
        return `${APIURLExtension.countryServerURL}/{}`;

      case APIType.ADD_CURRENCY:
        return `${APIURLExtension.currencyServerURL}{}`;
      case APIType.GET_LIST_CURRENCIES:
        return `${APIURLExtension.currencyServerURL}`;
      case APIType.GET_CURRENCY_DETAILS:
        return `${APIURLExtension.currencyServerURL}/{}`;


      case APIType.ADD_AREA:
        return `${APIURLExtension.areaServerURL}{}`;
      case APIType.GET_LIST_AREAS:
        return `${APIURLExtension.areaServerURL}`;
      case APIType.GET_AREA_DETAILS:
        return `${APIURLExtension.areaServerURL}/{}`;
      case APIType.DELETE_AREA:
        return `${APIURLExtension.areaServerURL}/{}`;


      case APIType.GET_PRODUCT_DETAILS:
        return `${APIURLExtension.productServerURL}/{}`;
      case APIType.DELETE_PRODUCT:
        return `${APIURLExtension.productServerURL}/{}`;
      case APIType.UPDATE_PRODUCT:
        return `${APIURLExtension.productServerURL}/{}`;
      case APIType.ADD_PRODUCT_IMAGE:
        return `${APIURLExtension.productServerURL}/{}/product-image`;
      case APIType.DELETE_PRODUCT_IMAGE:
        return `${APIURLExtension.productImageServerURL}/{}`;


      case APIType.ADD_USER:
        return `${APIURLExtension.userServerURL}{}`;
      case APIType.GET_USER_DETAILS:
        return `${APIURLExtension.userServerURL}/{}`;
      case APIType.DELETE_USER:
        return `${APIURLExtension.userServerURL}/{}`;
      case APIType.BLOCK_USER:
        return `${APIURLExtension.userServerURL}/{}/block`;
      case APIType.UNBLOCK_USER:
        return `${APIURLExtension.userServerURL}/{}/unblock`;
      case APIType.GET_FIREBASE_TOKEN:
        return `${APIURLExtension.getFirebaseTokenServerURL}`;

      case APIType.GET_ACCOUNT_INFO:
        return `${APIURLExtension.accountServerURL}`;
      case APIType.UPDATE_ACCOUNT_INFO:
        return `${APIURLExtension.accountServerURL}`;
      case APIType.UPDATE_PASSWORD:
        return `${APIURLExtension.accountServerURL}/password`;

      case APIType.GET_ALL_MERCHANT_RATE:
        return `${APIURLExtension.merchantServerURL}/rating`;

      case APIType.GET_ALL_MERCHANT_RATE:
        return `${APIURLExtension.merchantServerURL}/rating`;


      case APIType.GET_MERCHANT_RATE:
        return `${APIURLExtension.merchantServerURL}/{}/rating`;

      case APIType.GET_MERCHANT_PRODUCT_RATE:
        return `${APIURLExtension.merchantServerURL}/{}/product/rating`;

      case APIType.GET_ALL_PRODUCT_RATE:
        return `${APIURLExtension.productServerURL}/rating`;

      case APIType.GET_PRODUCT_RATE:
        return `${APIURLExtension.productServerURL}/{}/rating`;

      case APIType.APPROVE_RATE:
        return `${APIURLExtension.rateServerURL}/{}/approve`;


      case APIType.REJECT_RATE:
        return `${APIURLExtension.rateServerURL}/{}/reject`;

      case APIType.DELETE_RATE:
        return `${APIURLExtension.rateServerURL}/{}`;


      case APIType.GET_RATE_DETAILS:
        return `${APIURLExtension.rateServerURL}/{}`;
      case APIType.ADD_REPLAY_RATE:
        return `${APIURLExtension.rateServerURL}/{}/reply`;

      case APIType.DELETE_REPLAY_RATE:
        return `${APIURLExtension.rateServerURL}/{}/reply`;



      default:
        return "";
    }
  }
}
