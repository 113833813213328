import { Injectable } from "@angular/core";
import { APIClientService } from "../../services/apiclient.service";
import { catchError, Observable, tap, throwError } from "rxjs";
import { APIType } from "../../common/enums/APIType";
import { RequestType } from "../../common/enums/RequestType";
import { CityFilterData } from "../classes/CityFilterData";
import { City } from "../interfaces/City";
import { ListCitiesResponse } from "../interfaces/response-pagination/ListCitiesResponse";
import { FilterMetaData } from "../../common/classes/FilterMetaData";


@Injectable({ providedIn: 'root' })
export class CityAPI {
  constructor(private readonly apiClient: APIClientService) {

  }


  save(parameters: {
    city: City,
  }): Observable<string> {

    let city = parameters.city;
    let id = parameters.city.id ?? "";
    let dataUrl = id.hasActualValue() ? `/${id}` : "";
    let request = {
      id: city.id,
      nameEn: city.nameEn,
      nameAr: city.nameAr,
      countryISO3letter: city.country,
    }


    return this.apiClient.call<any, string>({
      apiType: APIType.ADD_CITY,
      requestType: RequestType.POST,
      body: request,
      dataUrl,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  getList(filterData: CityFilterData): Observable<ListCitiesResponse> {
    let parameter = CityFilterData.getParameterApi(filterData);
    return this.apiClient.call<any, ListCitiesResponse>({
      apiType: APIType.GET_LIST_CITIES,
      body: parameter,
      requestType: RequestType.GET,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }


  getDetails(parameters: {
    cityId: string,
  }): Observable<City> {
    let dataUrl = parameters.cityId;
    return this.apiClient.call<any, City>({
      apiType: APIType.GET_CITY_DETAILS,
      requestType: RequestType.GET,
      dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }

  getAll(): Observable<ListCitiesResponse> {
   let filterMetaData =  new FilterMetaData();
    filterMetaData.itemsPerPage = 1000;
    filterMetaData.currentPage = 1;
    let parameter = FilterMetaData.getParameterApi(filterMetaData);
    return this.apiClient.call<any, ListCitiesResponse>({
      apiType: APIType.GET_LIST_CITIES,
      body: parameter,
      requestType: RequestType.GET,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

}

