
<div class="wrapper">
    <div id="sidebar" class="sidebar js-sidebar">
      <div class="sidebar-content js-simplebar">
        <app-sidebar ></app-sidebar>
     </div>
    </div>

  <div class="main">
    <app-header></app-header>
    <main class="content">
      <router-outlet ></router-outlet>
    </main>
    <app-footer></app-footer>
  </div>
</div>








