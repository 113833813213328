import { Injectable } from "@angular/core";
import { APIClientService } from "../../services/apiclient.service";
import { catchError, Observable, tap, throwError } from "rxjs";
import { Merchant } from "../interfaces/Merchant";
import { APIType } from "../../common/enums/APIType";
import { RequestType } from "../../common/enums/RequestType";
import { Address } from "../interfaces/Address";
import { Phone } from "../interfaces/Phone";
import { Website } from "../interfaces/Website";
import { SocialMediaPlatform } from "../interfaces/SocialMediaPlatform";
import { GeoLocation } from "../interfaces/GeoLocation";
import { compare } from "../../common/utilities/General";
import { MediaType } from "../enums/MediaType";
import { MerchantService } from "../../services/merchant.service";


@Injectable({ providedIn: 'root' })
export class MerchantAPI {
  constructor(private readonly apiClient: APIClientService,
              public merchantService: MerchantService,) {

  }

  save(parameters: {
    merchant: Merchant,
    oldMerchant?: Merchant | null,
  }): Observable<string> {

    let merchant = parameters.merchant;
    let old = parameters.oldMerchant;
    let dataUrl = parameters.merchant.id ?? "";
    dataUrl = dataUrl.hasActualValue() ? `/${dataUrl}` : "";
    let profilePictureURL = merchant.profilePictureURL
    let coverPhotoURL = merchant.coverPhotoURL
    let slug = compare(old?.slug ?? "", merchant.slug);
    //We cannot let merchants enter it themselves, at least not now
    let request = {
      nameEn: merchant.nameEn,
      nameAr: merchant.nameAr,
      briefEn: merchant.briefEn,
      briefAr: merchant.briefAr,
      deliveryAvailable: merchant.deliveryAvailable,
      pickupAvailable: merchant.pickupAvailable,
      coverPhotoURL: coverPhotoURL,
      profilePictureURL: profilePictureURL,
      taxIdentificationNumber: merchant.taxIdentificationNumber,
    }

    return this.apiClient.call<any, string>({
      apiType: APIType.addMerchant,
      requestType: RequestType.POST,
      body: request,
      dataUrl,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  getDetails(parameters: {
    merchantId: string,
  }): Observable<Merchant> {
    let dataUrl = parameters.merchantId;
    return this.apiClient.call<any, Merchant>({
      apiType: APIType.getMerchantDetails,
      requestType: RequestType.GET,
      dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
        this.merchantService.merchant  = response
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  addArea(parameters: {
    merchantId: string,
    areaId: string,
  }): Observable<void> {

    let dataUrl = parameters.merchantId
    let request = {
      areaId: parameters.areaId,
    }

    return this.apiClient.call<any, void>({
      apiType: APIType.ADD_MERCHANT_AREA,
      requestType: RequestType.POST,
      dataUrl: dataUrl,
      body: request,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  deleteArea(parameters: {
    merchantId: string,
    areaId: string,
  }): Observable<void> {
    let dataUrl = [parameters.merchantId, parameters.areaId]
    return this.apiClient.call<any, void>({
      apiType: APIType.DELETE_MERCHANT_AREA,
      requestType: RequestType.DELETE,
      listDataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  addAddress(parameters: {
    merchantId: string,
    address: Address,
  }): Observable<void> {

    let dataUrl = [parameters.merchantId]
    let address = parameters.address
    let request = {
      id: address.id,
      addressEn: address.addressEn,
      addressAr: address.addressAr,
      streetEn: address.streetEn,
      streetAr: address.streetAr,
      buildingEn: address.buildingEn,
      buildingAr: address.buildingAr,
      floor: address.floor,
      postCode: address.postCode,
      cityId: address.cityId,
    }

    return this.apiClient.call<any, void>({
      apiType: APIType.ADD_MERCHANT_ADDRESS,
      requestType: RequestType.POST,
      listDataUrl: dataUrl,
      body: request,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  deleteAddress(parameters: {
    merchantId: string,
    addressId: string,
  }): Observable<void> {
    let dataUrl = [parameters.merchantId, parameters.addressId]
    return this.apiClient.call<any, void>({
      apiType: APIType.DELETE_MERCHANT_ADDRESS,
      requestType: RequestType.DELETE,
      listDataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  addPhone(parameters: {
    merchantId: string,
    phone: Phone,
  }): Observable<void> {

    let dataUrl = [parameters.merchantId]
    let phone = parameters.phone

    let request = {
      id: phone.id,
      countryCode: phone.countryCode,
      phoneNumber: phone.phoneNumber,
      hasWhatsapp: phone.hasWhatsapp,
      hasTelegram: phone.hasTelegram,
    }

    return this.apiClient.call<any, void>({
      apiType: APIType.ADD_MERCHANT_PHONE,
      requestType: RequestType.POST,
      listDataUrl: dataUrl,
      body: request,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }

  deletePhone(parameters: {
    merchantId: string,
    phoneId: string,
  }): Observable<void> {
    let dataUrl = [parameters.merchantId, parameters.phoneId]
    return this.apiClient.call<any, void>({
      apiType: APIType.DELETE_MERCHANT_PHONE,
      requestType: RequestType.DELETE,
      listDataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }

  addWebsite(parameters: {
    merchantId: string,
    website: Website,
  }): Observable<void> {

    let dataUrl = [parameters.merchantId]
    let website = parameters.website

    let request = {
      id: website.id,
      url: website.url,
    }

    return this.apiClient.call<any, void>({
      apiType: APIType.ADD_MERCHANT_WEBSITE,
      requestType: RequestType.POST,
      listDataUrl: dataUrl,
      body: request,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  deleteWebsite(parameters: {
    merchantId: string,
    websiteId: string,
  }): Observable<void> {
    let dataUrl = [parameters.merchantId, parameters.websiteId]
    return this.apiClient.call<any, void>({
      apiType: APIType.DELETE_MERCHANT_WEBSITE,
      requestType: RequestType.DELETE,
      listDataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  addSocialMediaPlatform(parameters: {
    merchantId: string,
    socialMediaPlatform: SocialMediaPlatform,
  }): Observable<void> {

    let dataUrl = [parameters.merchantId]
    let socialMediaPlatform = parameters.socialMediaPlatform

    let request = {
      id: socialMediaPlatform.id,
      url: socialMediaPlatform.url,
      platform: socialMediaPlatform.platform,
    }

    return this.apiClient.call<any, void>({
      apiType: APIType.ADD_MERCHANT_SOCIAL_MEDIA_PLATFORM,
      requestType: RequestType.POST,
      listDataUrl: dataUrl,
      body: request,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  deleteSocialMediaPlatform(parameters: {
    merchantId: string,
    socialMediaPlatformId: string,
  }): Observable<void> {
    let dataUrl = [parameters.merchantId, parameters.socialMediaPlatformId]
    return this.apiClient.call<any, void>({
      apiType: APIType.DELETE_MERCHANT_SOCIAL_MEDIA_PLATFORM,
      requestType: RequestType.DELETE,
      listDataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  addGeoLocation(parameters: {
    merchantId: string,
    geoLocation: GeoLocation,
  }): Observable<void> {

    let dataUrl = [parameters.merchantId]
    let geolocation = parameters.geoLocation

    let request = {
      longitude: geolocation.longitude,
      latitude: geolocation.latitude,
    }

    return this.apiClient.call<any, void>({
      apiType: APIType.ADD_MERCHANT_GEOLOCATION,
      requestType: RequestType.POST,
      listDataUrl: dataUrl,
      body: request,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  deleteGeoLocation(parameters: {
    merchantId: string,
    geoLocationId: string,
  }): Observable<void> {

    let dataUrl = [parameters.merchantId, parameters.geoLocationId]
    return this.apiClient.call<any, void>({
      apiType: APIType.DELETE_MERCHANT_GEOLOCATION,
      requestType: RequestType.DELETE,
      listDataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }

  addImage(parameters: {
    merchantId: string,
    type: MediaType,
    url: string
  }): Observable<string> {

    let dataUrl = [parameters.merchantId];
    let request = {
      url: parameters.url,
      type: parameters.type,
    }

    return this.apiClient.call<any, string>({
      apiType: APIType.ADD_MERCHANT_IMAGE,
      requestType: RequestType.POST,
      body: request,
      listDataUrl: dataUrl,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  deleteImage(parameters: {
    merchantId: string,
    imageId: string,
  }): Observable<void> {
    let dataUrl = [parameters.merchantId, parameters.imageId]
    return this.apiClient.call<any, void>({
      apiType: APIType.DELETE_MERCHANT_IMAGE,
      requestType: RequestType.DELETE,
      listDataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }

  resendMerchantPhoneSMS(parameters:{countryCode: string,
                         phoneNumber: string,
                         merchantId:string}): Observable<void> {
    let dataUrl = [parameters.merchantId];

    let request = {
      "countryCode": parameters.countryCode,
      "phoneNumber": parameters.phoneNumber
    }
    return this.apiClient.call<any, void>({
      apiType: APIType.resendMerchantVerificationSMSCode,
      requestType: RequestType.POST,
      body: request,
      listDataUrl: dataUrl,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  verifyMerchantPhone(parameters:{token: string,merchantId: string,}) {
    let dataUrl = [parameters.merchantId,parameters.token];

    return this.apiClient.call<any, void>({
      apiType: APIType.verifyMerchantCode,
      requestType: RequestType.POST,
      body: { } ,
      listDataUrl: dataUrl,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )

  }

  addMenu(parameters: {
    merchantId: string,
    type: MediaType,
    url: string
  }): Observable<string> {

    let dataUrl = [parameters.merchantId];
    let request = {
      url: parameters.url,
      type: parameters.type,
    }

    return this.apiClient.call<any, string>({
      apiType: APIType.ADD_MENU_ITEM,
      requestType: RequestType.POST,
      body: request,
      listDataUrl: dataUrl,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  deleteMenu(parameters: {
    merchantId: string,
    imageId: string,
  }): Observable<void> {
    let dataUrl = [parameters.merchantId, parameters.imageId]
    return this.apiClient.call<any, void>({
      apiType: APIType.DELETE_MENU_ITEM,
      requestType: RequestType.DELETE,
      listDataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


}
