export enum ProductNameId {
  ID = 'id',
  NAME_EN = 'nameEn',
  NAME_AR = 'nameAr',
  DESCRIPTION_EN = 'descriptionEn',
  DESCRIPTION_AR = 'descriptionAr',
  CATEGORY_ID = 'categoryId',
  MERCHANT_ID = 'merchantId',
  STATE = 'state',
  READY_WITHIN = 'readyWithin',
  PRICE = 'price',
  CURRENCY = 'currency',
  THUMBNAIL_URL = 'thumbnailURL',
  IMAGES = 'images',
  CREATED_AT = 'createdAt',
  SLUG = 'slug'
}
