import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Product} from "../../../../business/interfaces/Product";
import {InputField} from "../../../interfaces/InputField";
import {FormGroup, Validators} from "@angular/forms";
import {ErrorHandling} from "../../../../common/classes/ErrorHandling";
import {ActivatedRoute} from "@angular/router";
import {AppNavigator} from "../../../../services/app-navigator";
import {ProductAPI} from "../../../../business/apis/ProductAPI";
import {InputFieldType} from "../../../../components/input/enum/InputFieldType";
import {InputType} from "../../../../components/input/enum/InputType";
import {isFormSubmitted} from "../../../../common/utilities/FormsHelper";
import {ProductNameId} from "../../../../business/name-id/ProductNameId";
import { generateFilePaths, getEnumFromValue } from "../../../../common/utilities/General";
import {Category} from "../../../../business/interfaces/Category";
import {Currency} from "../../../../business/interfaces/Currency";
import {OptionSelect} from "../../../../components/filters/enum/filter-type";
import {ProductStateHelper} from "../../../../business/enums/ProductState";
import {ProductReadyWithinStateHelper} from "../../../../business/enums/ProductReadyWithin";


@Component({
  selector: 'product-basic-info',
  templateUrl: './product-basic-info.component.html',
  styleUrls: ['./product-basic-info.component.css']
})
export class ProductBasicInfoComponent {

  @Input() merchantId: string = ""
  @Input() loadData: BehaviorSubject<any> | null = null;
  @Output() reloadData = new EventEmitter<string>();

  productDetails: Product | null = null;
  productFields: InputField[] = [];
  form: FormGroup = new FormGroup({});
  errorHandling: ErrorHandling | null = null;
  isLoading = false;

  nameEnglishItem!: InputField;
  nameArabicItem!: InputField;
  descriptionEnglishItem!: InputField;
  descriptionArabicItem!: InputField;
  readyWithinItem!: InputField;
  priceItem!: InputField;
  categoryItem!: InputField;
  currencyItem!: InputField;
  productStateItem!: InputField;
  thumbnailItem!: InputField;
  slugItem!: InputField;

  thumbnailProductFilePath: string =  "";


  constructor(
    private activatedRoute: ActivatedRoute,
    private appNavigator: AppNavigator,
    private productApi: ProductAPI
  ) {
  }

  ngOnInit(): void {
    this.productFields = this.getInputFields();


    this.loadData!.subscribe((data) => {
      this.productDetails = data;
      this.reloadDataViews();
      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.loadData?.unsubscribe();
  }

  ngAfterViewInit(): void {
  }

  private getInputFields(): InputField[] {
    this.nameEnglishItem = {
      id: ProductNameId.NAME_EN,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "Name English",
      validators: [Validators.required],
      value: "",
      loadData: new BehaviorSubject<any>(""),
    } as InputField;

    this.nameArabicItem = {
      id: ProductNameId.NAME_AR,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "Name Arabic",
      validators: [Validators.required],
      value: "",
      loadData: new BehaviorSubject<any>(""),
    } as InputField;

    this.descriptionEnglishItem = {
      id: ProductNameId.DESCRIPTION_EN,
      inputFieldType: InputFieldType.textArea,
      floatingLabel: "Description English",
      validators: [],
      value: "",
      loadData: new BehaviorSubject<any>(""),
    } as InputField;

    this.descriptionArabicItem = {
      id: ProductNameId.DESCRIPTION_AR,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "Description Arabic",
      validators: [],
      value: "",
      loadData: new BehaviorSubject<any>(""),
    } as InputField;


    this.readyWithinItem = {
      id: ProductNameId.READY_WITHIN,
      floatingLabel: "Ready Within",
      validators: [Validators.required],
      loadData: new BehaviorSubject<any>(null),
    } as InputField;


    this.priceItem = {
      id: ProductNameId.PRICE,
      inputFieldType: InputFieldType.textField,
      floatingLabel: "Price",
      validators: [],
      value: "",
      loadData: new BehaviorSubject<any>(""),
    } as InputField;


    this.categoryItem = {
      id: ProductNameId.CATEGORY_ID,
      inputFieldType: InputFieldType.textField,
      floatingLabel: "Category",
      validators: [Validators.required],
      value: null,
      loadData: new BehaviorSubject<any>(""),
    } as InputField;

    this.currencyItem = {
      id: ProductNameId.CURRENCY,
      inputFieldType: InputFieldType.textField,
      floatingLabel: "Currency",
      validators: [Validators.required],
      value: null,
      loadData: new BehaviorSubject<any>(""),
    } as InputField;


    this.productStateItem = {
      id: ProductNameId.STATE,
      floatingLabel: "State",
      validators: [Validators.required],
      loadData: new BehaviorSubject<any>(null),
    } as InputField;


    this.thumbnailItem = {
      id: ProductNameId.THUMBNAIL_URL,
      inputFieldType: InputFieldType.image,
      label: "Thumbnail",
      validators: [],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;


    this.slugItem = {
      id: ProductNameId.SLUG,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "Slug",
      validators: [],
      loadData: new BehaviorSubject<any>(""),
    } as InputField;


    return [
      this.nameEnglishItem,
      this.nameArabicItem,
      this.descriptionEnglishItem,
      this.descriptionArabicItem,
      this.readyWithinItem,
      this.priceItem,
      this.categoryItem,
      this.currencyItem,
      this.productStateItem,
      this.thumbnailItem,
      this.slugItem,
    ];
  }

  getListProductReadyWithin(): OptionSelect[] {
    return ProductReadyWithinStateHelper.getListOption();
  }

  getListProductState(): OptionSelect[] {
    return ProductStateHelper.getListOption();
  }

  getInputFieldById(id: ProductNameId): InputField | undefined {
    let item = this.productFields.find((field) => field.id === id);
    return item;
  }

  updateInputFieldById(id: string, value: any) {
    let fieldId = getEnumFromValue(ProductNameId, id) as ProductNameId;
    let field = this.getInputFieldById(fieldId);
    if (field != undefined) {
      field.value = value;
    }
  }

  getValueFieldById(id: string): Object {
    let fieldId = getEnumFromValue(ProductNameId, id) as ProductNameId;
    let field = this.getInputFieldById(fieldId);
    return field?.value ?? null;
  }

  submit() {
    if (isFormSubmitted(this.form)) {
      this.updateProduct();
    }
  }

  updateProduct() {
    let category = this.getValueFieldById(ProductNameId.CATEGORY_ID) as Category
    let currency = this.getValueFieldById(ProductNameId.CURRENCY) as Currency
    const productReadyWithin = (this.getValueFieldById(ProductNameId.READY_WITHIN)  as OptionSelect).value;
    let productState = (this.getValueFieldById(ProductNameId.STATE)  as OptionSelect).value;

    let product = {
      id: this.productDetails?.id ?? "",
      nameEn:
        this.getValueFieldById(ProductNameId.NAME_EN)?.toStringValue() ?? "",
      nameAr:
        this.getValueFieldById(ProductNameId.NAME_AR)?.toStringValue() ?? "",
      descriptionEn:
        this.getValueFieldById(ProductNameId.DESCRIPTION_EN)?.toStringValue() ?? "",
      descriptionAr:
        this.getValueFieldById(ProductNameId.DESCRIPTION_AR)?.toStringValue() ?? "",
      readyWithin: productReadyWithin,
      price:
        (this.getValueFieldById(
          ProductNameId.PRICE) as  string ?? "").toCents(),
      categoryId: category?.id ?? "",
      currency: currency?.iso3letter ?? "",
      state: productState?? "",
      thumbnailURL:
        this.getValueFieldById(ProductNameId.THUMBNAIL_URL) as string ?? "",
      slug: this.getValueFieldById(ProductNameId.SLUG) as string ?? "",
    } as Product;

    console.log(product);
    this.restError();
    this.isLoading = true;
    let merchantId = this.merchantId
    this.productApi.save({product: product, merchantId: merchantId}).subscribe({
      next: (item) => {
        console.log(item);
        let productId = this.productDetails?.id ?? "";
        if (item.hasValue()) {
          productId = item;
        }
        this.reloadData!.emit(productId);
      },
      error: (error: any) => {
        this.checkError(error);
        this.isLoading = false;
      },
    });
  }

  reloadDataViews() {

    let filePaths = generateFilePaths({
      merchantId: this.merchantId ?? "",
      productId: this.productDetails?.id ?? "",
    })
    this.thumbnailProductFilePath = filePaths.thumbnailProductFilePath;

    this.getInputFieldById(ProductNameId.NAME_EN)?.loadData?.next(
      this.productDetails?.nameEn
    );
    this.getInputFieldById(ProductNameId.NAME_AR)?.loadData?.next(
      this.productDetails?.nameAr
    );
    this.getInputFieldById(ProductNameId.DESCRIPTION_EN)?.loadData?.next(
      this.productDetails?.descriptionEn
    );
    this.getInputFieldById(ProductNameId.DESCRIPTION_AR)?.loadData?.next(
      this.productDetails?.descriptionAr
    );

    this.getInputFieldById(ProductNameId.THUMBNAIL_URL)?.loadData?.next(
      this.productDetails?.thumbnailURL
    );



    let productState = ProductStateHelper.getOption(this.productDetails?.state ?? "")
    this.getInputFieldById(
      ProductNameId.STATE
    )?.loadData?.next(productState);

    let readyWithin = ProductReadyWithinStateHelper.getOption(this.productDetails?.readyWithin ?? "")
    this.getInputFieldById(
      ProductNameId.READY_WITHIN
    )?.loadData?.next(readyWithin);


    this.getInputFieldById(ProductNameId.PRICE)?.loadData?.next(
      this.productDetails?.price?.toString().toDollars()
    );

    this.getInputFieldById(ProductNameId.CATEGORY_ID)?.loadData?.next(
      this.productDetails?.categoryId
    );

    this.getInputFieldById(ProductNameId.CURRENCY)?.loadData?.next(
      this.productDetails?.currency
    );

    this.getInputFieldById(ProductNameId.SLUG)?.loadData?.next(
      this.productDetails?.slug
    );

  }

  checkError(error: any) {
    let errorHandling = ErrorHandling.configurationError(error);
    this.errorHandling = errorHandling;
  }

  restError() {
    this.errorHandling = null;
  }
}
