<nav aria-label="breadcrumb" style="--bs-breadcrumb-divider: '>';">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a (click)="navigateBack()" class="link-primary">List Addresses</a></li>
    <li aria-current="page" class="breadcrumb-item active">Add</li>
  </ol>
</nav>
<!-- Show this title only if myObject is null -->
<h1 class="h3 mb-3"><strong>Add</strong> Address</h1>

<div class="fields-layout">

  <!-- Country-search-text-field -->
  <country-search-text-field (onchange)="onSelectCountry($event)"
                             [errorHandling]="errorHandling"
                             [group]="form"
                             [id]="CountryNameId.ISO3_LETTER"
                             [validators]="[Validators.required]"
                             class="input-field-layout">
  </country-search-text-field>


  <!-- city-search-text-field -->
  <city-search-text-field (onchange)="onSelectCity($event)"
                          *ngIf="isCityAvailable()"
                          [errorHandling]="errorHandling"
                          [group]="form"
                          [id]="'city_id'"
                          [validators]="[Validators.required]"
                          class="input-field-layout">
  </city-search-text-field>


  <!-- addressEnItem -->
  <input-text-field (onchange)="updateInputFieldById(addressEn.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="addressEn.floatingLabel"
                    [group]="form"
                    [id]="addressEn.id.toString()"
                    [inputType]="addressEn.type"
                    [loadData]="addressEn.loadData"
                    [validators]="addressEn.validators"
                    class="input-field-layout"
  ></input-text-field>


  <!-- addressArItem -->
  <input-text-field (onchange)="updateInputFieldById(addressAr.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="addressAr.floatingLabel"
                    [group]="form"
                    [id]="addressAr.id.toString()"
                    [inputType]="addressAr.type"
                    [loadData]="addressAr.loadData"
                    [validators]="addressAr.validators"
                    class="input-field-layout"
  ></input-text-field>


  <!-- streetEn -->
  <input-text-field (onchange)="updateInputFieldById(streetEn.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="streetEn.floatingLabel"
                    [group]="form"
                    [id]="streetEn.id.toString()"
                    [inputType]="streetEn.type"
                    [loadData]="streetEn.loadData"
                    [validators]="streetEn.validators"
                    class="input-field-layout"

  ></input-text-field>


  <!-- streetAr -->
  <input-text-field (onchange)="updateInputFieldById(streetAr.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="streetAr.floatingLabel"
                    [group]="form"
                    [id]="streetAr.id.toString()"
                    [inputType]="streetAr.type"
                    [loadData]="streetAr.loadData"
                    [validators]="streetAr.validators"
                    class="input-field-layout"
  ></input-text-field>


  <!-- buildingEn -->
  <input-text-field (onchange)="updateInputFieldById(buildingEn.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="buildingEn.floatingLabel"
                    [group]="form"
                    [id]="buildingEn.id.toString()"
                    [inputType]="buildingEn.type"
                    [loadData]="buildingEn.loadData"
                    [validators]="buildingEn.validators"
                    class="input-field-layout"
  ></input-text-field>

  <!-- buildingAr -->
  <input-text-field (onchange)="updateInputFieldById(buildingAr.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="buildingAr.floatingLabel"
                    [group]="form"
                    [id]="buildingAr.id.toString()"
                    [inputType]="buildingAr.type"
                    [loadData]="buildingAr.loadData"
                    [validators]="buildingAr.validators"
                    class="input-field-layout"
  ></input-text-field>

  <!-- floor -->
  <input-text-field (onchange)="updateInputFieldById(floor.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="floor.floatingLabel"
                    [group]="form"
                    [id]="floor.id.toString()"
                    [inputType]="floor.type"
                    [loadData]="floor.loadData"
                    [validators]="floor.validators"
                    class="input-field-layout"
  ></input-text-field>

  <!-- postCode -->
  <input-text-field (onchange)="updateInputFieldById(postCode.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="postCode.floatingLabel"
                    [group]="form"
                    [id]="postCode.id.toString()"
                    [inputType]="postCode.type"
                    [loadData]="postCode.loadData"
                    [validators]="postCode.validators"
                    class="input-field-layout"
  ></input-text-field>


</div>

<div class="action-button">
  <primary-button
    (onClick)="submit()"
    [loading]="isLoading"
    [title]="'Add'">
  </primary-button>
</div>

<div *ngIf="errorHandling != null && errorHandling.errorMessage.hasActualValue()" class="alert alert-danger"
     role="alert">
  {{ errorHandling.errorMessage }}
</div>






