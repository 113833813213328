export enum UserNameId {
  ID = 'id',
  NAME = 'name',
  EMAIL = 'email',
  COUNTRY_CODE = "countryCode",
  PHONE_NUMBER = "phoneNumber",
  BIRTHDATE = "birthDate",
  GENDER = "gender",
  EMAIL_VERIFIED = "emailVerified",
  PASSWORD = "password",
  IS_ADMIN = "isAdmin",
  MERCHANT_ID = 'merchantId',
  IS_VERIFIED = "isVerified",
  IS_BLOCKED = 'isBlocked',
  IS_DELETED = 'isDeleted',
  CREATED_AT = 'createdAt',

}


// "id": "901568f0-f366-4cd4-bfa8-f72d43acc277",
//   "name": "AbdelRahman",
//   "email": "aelsergani@gmail.com5",
//   "emailVerified": "false",
//   "countryCode": "20",
//   "phoneNumber": "12345678",
//   "birthDate": "1987-08-06",
//   "isAdmin": true,
//   "createdAt": "2024-04-15T07:41:45Z",
//   "isBlocked": "false",
//   "isDeleted": "false"
