<div class="modal fade modal-background" id="mobileNumberVerificationModal" #mobileNumberVerificationModal tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="modalLabel" aria-hidden="true" (click)="onContainerClicked($event)">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <form class="login-form" id="mobileNumberVerificationForm" [formGroup]="mobileNumberVerificationForm" (ngSubmit)="submitMobileNumberVerificationForm()">
        <div class="modal-header">
          <h2>Verify Mobile Number</h2>
          <button type="button" class="modal-close-button" (click)="closeModal()" aria-label="Close">
            <i class="bi bi-x-lg"></i>
          </button>
        </div>

        <div class="modal-body">
          <div>
            <p class="mb-3">Please enter the 6-digit code you received now via SMS.</p>
            <div class="mobile-verification-code-div">
              <input type="number" class="form-control number-input no-validation-error-icon" maxlength="1" id="digit1" #digitOne (keyup)="onPINCodeKeyup($event, digitTwo, undefined)" formControlName="digit1" [ngClass]="{'is-invalid': digit1?.touched && digit1?.invalid}" />
              <input type="number" class="form-control number-input no-validation-error-icon" maxlength="1" id="digit2" #digitTwo (keyup)="onPINCodeKeyup($event, digitThree, digitOne)" formControlName="digit2" [ngClass]="{'is-invalid': digit2?.touched && digit2?.invalid}" />
              <input type="number" class="form-control number-input no-validation-error-icon" maxlength="1" id="digit3" #digitThree (keyup)="onPINCodeKeyup($event, digitFour, digitTwo)" formControlName="digit3" [ngClass]="{'is-invalid': digit3?.touched && digit3?.invalid}" />
              <input type="number" class="form-control number-input no-validation-error-icon" maxlength="1" id="digit4" #digitFour (keyup)="onPINCodeKeyup($event, digitFive, digitThree)" formControlName="digit4" [ngClass]="{'is-invalid': digit4?.touched && digit4?.invalid}" />
              <input type="number" class="form-control number-input no-validation-error-icon" maxlength="1" id="digit5" #digitFive (keyup)="onPINCodeKeyup($event, digitSix, digitFour)" formControlName="digit5" [ngClass]="{'is-invalid': digit5?.touched && digit5?.invalid}" />
              <input type="number" class="form-control number-input no-validation-error-icon" maxlength="1" id="digit6" #digitSix (keyup)="onPINCodeKeyup($event, undefined, digitFive)" formControlName="digit6" [ngClass]="{'is-invalid': digit6?.touched && digit6?.invalid}" />
            </div>
            <p class="mb-3">You can send another code in <span class="resend-timer">{{ resendTimerSeconds | secondsToMinutesSeconds }}</span></p>
            <div class="resend-button-div">
              <p ><b>Didn't receive code?</b></p>
              <button class="resend-button" [disabled]="disableResendVerificationButton" (click)="resendMobileNumberVerificationCode(true)"><b>Resend</b></button>
            </div>
          </div>
        </div>

        <div class="alert alert-success form-alert" *ngIf="verifyMobileNumberSuccessMessage !== null">{{ verifyMobileNumberSuccessMessage }}</div>

        <div *ngIf="errorMessage != null && errorMessage.hasActualValue()" class="alert alert-danger"
             role="alert">
          {{ errorMessage }}
        </div>

        <div class="verify-button-modal-div">
          <button class="btn submit-button button-primary" type="submit" id="verify-number-modal" *ngIf="!loadingButton">Verify Mobile</button>
          <button class="button-primary" type="button" disabled *ngIf="loadingButton">
            <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            <span class="visually-hidden" role="status">Loading...</span>
          </button>
        </div>


      </form>
    </div>
  </div>
</div>






