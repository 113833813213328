<!--<ng-template #imageExists>-->

<div class="input-text-field-container">

  <div [style.display]="fileUploads.length > 0 ? 'block' : 'none'">
    <div>
      <div *ngFor="let fileUpload of fileUploads; let i = index" class="image-div">
        <div *ngIf="fileUpload != null && fileUpload.uploading; else imageUploaded" class="overlay">
          <p class="upload-percentage">{{ percentage }}%</p>
        </div>
        <ng-template #imageUploaded>

          <div *ngIf=!allowMulti>
            <img (click)="deleteFile(i)" class="delete-img" src="assets/img/svg/cancel-icon.svg"/>
          </div>

          <div *ngIf="fileUpload.id.hasActualValue()">
            <img (click)="deleteFile(i)" class="delete-img" src="assets/img/svg/cancel-icon.svg"/>
          </div>

        </ng-template>

        <img  [src]="getImageURL(fileUpload)" class="uploaded-image" (click)="openURLInNewTab(fileUpload)"/>
      </div>
    </div>
  </div>
  <!--</ng-template>-->


  <div [style.display]="allowMulti || fileUploads.length == 0 ? 'block' : 'none'">
    <div (fileDropped)="uploadFile($event)" appDragAndDrop class="dropzone">
      <input #fileDropRef (change)="fileSelected($event)" id="fileDropRef" type="file"/>
      <div class="upload-icon"><i class="bi bi-upload"></i></div>
      <p>Drag a file to this area to upload or click to browse.</p>
    </div>
  </div>

  <div *ngIf="this.errorMessage" class="invalid-feedback-style">{{ errorMessage }}</div>

</div>


