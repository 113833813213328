import { Injectable } from '@angular/core';
import { Merchant } from "../business/interfaces/Merchant";
import { BehaviorSubject } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class MerchantService {
  public merchant: Merchant | null = null
  isAccessTokenUpdated = false

  constructor() {
  }

  private myVariable = new BehaviorSubject<boolean | null>(null);

  isAuthenticationUpdated() {
    return this.myVariable.asObservable();
  }

  updateMerchantToken() {
    this.isAccessTokenUpdated = true
    this.myVariable.next(true);
  }


}
