
import './common/extensions/type-extensions';
import './common/extensions/string-extensions';
import './common/extensions/array-extensions';


import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from "@angular/forms";
import { FooterComponent } from './template/footer/footer.component';
import { HeaderComponent } from './template/header/header.component';
import { MasterComponent } from './template/master/master.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MerchantComponent } from './pages/merchant/merchant.component';
import { SidebarComponent } from './template/sidebar/sidebar.component';
import { HttpClientModule } from "@angular/common/http";
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { SelectFilter} from "./components/filters/select-filter.component";
import { DateRangeFilter} from "./components/filters/date-range-filter.component";
import { TableViewComponent } from './components/table-view/table-view.component';
import { AngularFireStorageModule } from "@angular/fire/compat/storage";
import { AngularFireModule } from "@angular/fire/compat";
import { environment } from "../environments/environment";
import {InputTextFieldComponent} from "./components/input/input-text-field/input-text-field.component";
import {PrimaryButtonComponent} from "./components/button/primary-button/primary-button.component";
import {FileUploadComponent} from "./components/file-upload/file-upload.component";
import {CheckBoxComponent} from "./components/input/check-box/check-box.component";
import {TextAreaComponent} from "./components/input/text-area/text-area.component";
import {InputPictureComponent} from "./components/input/input-picture/input-picture.component";
import { MerchantBasicInfoComponent } from './pages/merchant/pages/merchant-basic-info/merchant-basic-info.component';
import { CategoryDetailsComponent } from './pages/category-details/category-details.component';
import { CityDetailsComponent } from './pages/city-details/city-details.component';
import { DropDownListComponent } from './components/input/drop-down-list/drop-down-list.component';
import { CitySearchTextFieldComponent } from './pages/city-details/component/city-search-text-field/city-search-text-field.component';
import { CountryDetailsComponent } from './pages/country-details/country-details.component';
import {
  CountrySearchTextFieldComponent
} from "./pages/country-details/component/country-search-text-field/country-search-text-field.component";
import { AreaDetailsComponent } from './pages/area-details/area-details.component';
import { MobileNumberComponent } from './components/input/mobile-number/mobile-number.component';
import { NumericOnlyDirective } from './components/input/directive/numeric-only.directive';
import { MerchantAreasComponent } from './pages/merchant/pages/merchant-areas/merchant-areas.component';
import { CollapsibleHeaderComponent } from './components/collapsible-header/collapsible-header.component';
import { MerchantAreaDetailsComponent } from './pages/merchant-area-details/merchant-area-details.component';
import { AreaSearchTextFieldComponent } from './pages/area-details/component/area-search-text-field/area-search-text-field.component';
import { MerchantAddressComponent } from './pages/merchant/pages/merchant-address/merchant-address.component';
import { MerchantAddressDetailsComponent } from './pages/merchant-address-details/merchant-address-details.component';
import { MerchantPhonesComponent } from './pages/merchant/pages/merchant-phones/merchant-phones.component';
import { MerchantPhoneDetailsComponent } from './pages/merchant-phone-details/merchant-phone-details.component';
import { MerchantWebsitesComponent } from './pages/merchant/pages/merchant-websites/merchant-websites.component';
import { MerchantWebsiteDetailsComponent } from './pages/merchant-website-details/merchant-website-details.component';
import { MerchantSocialMediaPlatformsComponent } from './pages/merchant/pages/merchant-social-media-platforms/merchant-social-media-platforms.component';
import { MerchantSocialMediaPlatformDetailsComponent } from './pages/merchant-social-media-platform-details/merchant-social-media-platform-details.component';
import { MerchantGeoLocationsComponent } from './pages/merchant/pages/merchant-geo-locations/merchant-geo-locations.component';
import { MerchantGeoLocationDetailsComponent } from './pages/merchant-geo-location-details/merchant-geo-location-details.component';
import { MerchantProductsComponent } from './pages/merchant/pages/merchant-products/merchant-products.component';
import { ProductDetailsComponent } from './pages/product-details/product-details.component';
import { ProductBasicInfoComponent } from './pages/product-details/sections/product-basic-info/product-basic-info.component';
import {
  CategorySearchTextFieldComponent
} from "./pages/category-details/component/category-search-text-field/category-search-text-field.component";
import {
  CurrencySearchTextFieldComponent
} from "./pages/currency-details/component/currency-search-text-field/currency-search-text-field.component";
import { ProductImagesComponent } from './pages/product-details/sections/product-images/product-images.component';
import {MerchantImagesComponent} from "./pages/merchant/pages/merchant-media/sections/merchant-images/merchant-images.component";
import { UserDetailsComponent } from './pages/user-details/user-details.component';
import { MerchantUsersComponent } from './pages/merchant/pages/merchant-users/merchant-users.component';
import { NumberFilter } from "./components/filters/number-filter.component";
import { MerchantVideosComponent } from "./pages/merchant/pages/merchant-media/sections/merchant-video/merchant-videos.component";
import { ProductVideosComponent } from "./pages/product-details/sections/product-video/product-videos.component";
import { MerchantMediaComponent } from './pages/merchant/pages/merchant-media/merchant-media.component';
import { VerifyMobileNumberComponent } from './pages/merchant/pages/verify-mobile-number/verify-mobile-number.component';
import { SecondsToMinutesSecondsPipe } from "./pipes/SecondsToMinutesSecondsPipe";
import { EditProfileComponent } from "./pages/edit-profile/edit-profile.component";
import { InputDateTextFieldComponent } from "./components/input/input-date-text-field/input-date-text-field.component";
import { ChangePasswordComponent } from "./pages/change-password/change-password.component";
import { ListProductsRatesComponent } from "./pages/list-products-rates/list-products-rates.component";
import { CommentRateComponent } from "./pages/comment-rate/comment-rate.component";
import { ListMerchantsRatesComponent } from "./pages/list-merchants-rates/list-merchants-rates.component";
import {
  MerchantMenusComponent
} from "./pages/merchant/pages/merchant-products/merchant-menus/merchant-menus.component";


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    MasterComponent,
    LoginComponent,
    DashboardComponent,
    MerchantComponent,
    SidebarComponent,


    SelectFilter,
    DateRangeFilter,
    NumberFilter,
    TableViewComponent,
    InputTextFieldComponent,
    PrimaryButtonComponent,
    FileUploadComponent,
    CheckBoxComponent,
    TextAreaComponent,
    InputPictureComponent,
    MerchantBasicInfoComponent,
    CategoryDetailsComponent,
    CityDetailsComponent,
    DropDownListComponent,
    CitySearchTextFieldComponent,
    CountryDetailsComponent,
    CountrySearchTextFieldComponent,
    AreaDetailsComponent,
    MobileNumberComponent,
    NumericOnlyDirective,
    MerchantAreasComponent,
    CollapsibleHeaderComponent,
    MerchantAreaDetailsComponent,
    AreaSearchTextFieldComponent,
    MerchantAddressComponent,
    MerchantAddressDetailsComponent,
    MerchantPhonesComponent,
    MerchantPhoneDetailsComponent,
    MerchantWebsitesComponent,
    MerchantWebsiteDetailsComponent,
    MerchantSocialMediaPlatformsComponent,
    MerchantSocialMediaPlatformDetailsComponent,
    MerchantGeoLocationsComponent,
    MerchantGeoLocationDetailsComponent,
    MerchantProductsComponent,
    ProductDetailsComponent,
    ProductBasicInfoComponent,
    CategorySearchTextFieldComponent,
    CurrencySearchTextFieldComponent,
    ProductImagesComponent,
    MerchantImagesComponent,
    UserDetailsComponent,
    MerchantUsersComponent,
    MerchantVideosComponent,
    ProductVideosComponent,
    MerchantMediaComponent,
    VerifyMobileNumberComponent,
    SecondsToMinutesSecondsPipe,
    EditProfileComponent,
    InputDateTextFieldComponent,
    ChangePasswordComponent,
    ListProductsRatesComponent,
    ListMerchantsRatesComponent,
    CommentRateComponent,
    MerchantMenusComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxDatatableModule,
    NgxPaginationModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.FIREBASE_CONFIG),
    AngularFireStorageModule
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
