import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, ValidatorFn } from "@angular/forms";
import { NameValidationError } from "../validators/name-validator";
import { BehaviorSubject } from "rxjs";
import { ErrorHandling } from "../../../common/classes/ErrorHandling";
import { InputType } from "../enum/InputType";


@Component({
  selector: "input-text-field",
  templateUrl: "./input-text-field.component.html",
  styleUrls: ["./input-text-field.component.scss"],
})

export class InputTextFieldComponent implements OnInit {

  @Input() loadData?: BehaviorSubject<any>;
  @Output() onchange: EventEmitter<any> = new EventEmitter<any>();

  inputValue: string = "";
  @Input() errorHandling: ErrorHandling | null = null;

  @Input() inputType?: InputType = InputType.normal;
  @Input() floatingLabel?: string = "";
  @Input() id!: string;
  @Input() group!: FormGroup;
  @Input() validators: ValidatorFn[] = [];
  @Input() showEmailHint: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Input() enableNumericOnly: boolean = false;
  control: FormControl = new FormControl();
  isPasswordVisible: boolean = false;


  constructor() {
  }

  get errorMessage(): string {
    if (this.control.touched) {
      if (this.control.hasError("email")) {
        return "Email is not valid";
      }
      if (this.control.hasError("invalid")) {
        let message = `${this.floatingLabel} is invalid`;

        if (this.control.errors && this.control.hasError("message")) {
          message = this.control.errors["message"];
        }
        return message
      } else if (this.control.hasError(NameValidationError.tooShort)) {
        return `${this.floatingLabel} is too short`;
      } else if (this.control.hasError(NameValidationError.tooLong)) {
        return `${this.floatingLabel} is too long`;
      } else if (this.control.hasError(NameValidationError.invalidFormat)) {
        return `${this.floatingLabel} is invalidFormat`;
      } else if (this.control.hasError("required")) {
        return `${this.floatingLabel} is required`;
      } else if (this.control.hasError('minlength') || this.control.hasError('maxlength')) {
        return `${this.floatingLabel} is invalid`;
      }
    }
    if (this.errorHandling) {
      return this.getErrorMessage();
    }
    return "";
  }

  ngOnInit(): void {
    let validators = [];
    validators.push(...this.validators);
    this.control.setValidators(validators);
    this.group.addControl(this.id, this.control);

    this.loadData?.subscribe((data) => {
      this.inputValue = data;
      this.updateViews(data);
    });
  }

  isInputValid(): string {
    // console.log("is touched == " + this.id + " " + this.control.touched);
    if (this.control.touched) {
      if (
        this.control.hasError("email") ||
        this.control.hasError("required") ||
        this.control.hasError(NameValidationError.tooShort) ||
        this.control.hasError(NameValidationError.tooLong) ||
        this.control.hasError(NameValidationError.invalidFormat)
      ) {
        return "is-invalid";
      }
    }
    if (this.errorMessage.hasActualValue()) {
      return "is-invalid";
    }
    return "";
  }

  changePasswordState() {
    this.isPasswordVisible = !this.isPasswordVisible;
    this.inputType = this.isPasswordVisible
      ? InputType.visiblePassword
      : InputType.password;
  }

  visibilityIcon(): string {
    return this.isPasswordVisible ? "fa-eye" : "fa-eye-slash";
  }

  ngOnDestroy(): void {
    this.group?.removeControl(this.id);
  }

  onInputChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.updateViews(value);
  }

  updateViews(value: any) {
    this.onchange?.emit(value);
  }

  getErrorMessage() {
    let id = this.id ?? "";
    let title = this.floatingLabel ?? "";
    return this.errorHandling?.getValidationErrorMessage(id, title) ?? "";
  }
}
