export enum AreaNameId {
  ID = 'id',
  NAME_EN = 'nameEn',
  NAME_AR = 'nameAr',
  CITY = "city",
  CITY_NAME_EN = 'cityNameEn',
  CITY_NAME_AR = 'cityNameAr',
  CITY_ID = "cityId",
  CREATED_AT = 'createdAt',
}


